.word-matrix {
  display: grid;
  gap: 2rem;
}

/* Mobile: 1 column and auto rows */
@media screen and (max-width: 7000px) {
  .word-matrix {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: auto;
  }
}

/* Tablets: 2 columns and auto rows */
@media screen and (min-width: 700px) and (max-width: 1540px) {
  .word-matrix {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-auto-rows: auto;
  }
}

/* Desktop: 3 columns and auto rows */
@media screen and (min-width: 1540px) {
  .word-matrix {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
  }
}
