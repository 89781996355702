.skill-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem; /* Space between columns */
}

.skill-item {
  flex: 1 1 calc(50% - 1rem); /* Two columns */
}

@media (max-width: 768px) {
  .skill-item {
    flex: 1 1 100%; /* One column on smaller screens */
  }
}

.testimonial-one img {
  display: unset !important;
  width: 90%;
}
.coinDetails-datepiker .picker__container {
  position: relative !important;
}
.wallet-card {
  margin: 0 auto;
  width: 95% !important;
}
.dataTables_wrapper .dataTables_paginate span .paginate_button {
  display: inline-block;
}
/* ////////////////////////fixed */
.element-right-round button#dropdown-button-drop-dwon {
  border-radius: 0 0.75rem 0.75rem 0;
}
._i-hover:hover {
  left: 0 !important;
}
@media screen and (min-width: 695px) {
  .d-unset {
    display: unset !important;
  }
}
.d-unset {
  display: block;
}
button#check-minutes {
  border-radius: 0 0.75rem 0.75rem 0;
}
.fc-toolbar-chunk {
  display: flex;
}
@media screen and (max-width: 335px) {
  .fc-toolbar-chunk {
    display: block;
    text-align: center;
    line-height: 2.8;
  }
  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin: 0 !important;
  }
}
/* Editor */
.tox .tox-notification--warn,
.tox .tox-notification--warning {
  display: none !important;
}

/* Map */
svg.svg-map {
  stroke: white !important;
}
.svg-map__location {
  fill: #eff2f4 !important;
}
.svg-map__location:focus,
.svg-map__location:hover {
  fill: black !important;
}

.map-unselected {
  fill: #eff2f4;
}

.p-meddle {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}
/* color picker */

.chrome-picker {
  position: absolute;
  top: -310px;
}
.color-gradian-tixia .popover {
  top: -410px !important;
}
/* wizard */

/* date piker */
@media screen and (max-width: 590px) {
  .rangeDatePicker .calendar__item {
    display: block !important;
    width: 100%;
  }
  .rangeDatePicker .calendar__container {
    width: 100% !important;
  }
}

form#step-form-horizontal div:first-child > div:last-child {
  text-align: right;
  /* padding-top: 2rem; */
}

label.mailclinet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: #eef5f9;
  text-align: center;
  margin: auto;
}

.wizard-four-color {
  color: #222fb9;
  text-decoration: underline;
}

.redio-false {
  display: none;
}

span.mail-icon {
  font-size: 3rem;
  display: inline-block;
  line-height: 1;
  margin-top: -1rem;
}

span.mail-text {
  font-size: 1rem;
  text-align: center;
  margin-top: 0.5rem;
}

/* Wizard end */

/* cursor-pointer */
.cursor-pointer {
  cursor: pointer;
}
/* form */
#multiselect button.btn.dropdown-toggle.btn-light {
  background: no-repeat;
  border: 1px solid #d7dae3;
  color: #3e4954;
  font-weight: 100;
}
/* slider */

.card-text {
  margin-bottom: 0;
}
#whiteSpace {
  white-space: normal;
}

#tp-btn::after {
  content: "";
}

.swal-footer {
  text-align: center !important;
  margin: 0 !important;
}

.ui .progress .progress-bar {
  animation-name: animateBar;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.6s;
}
@keyframes animateBar {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
ol.go2441762052 {
  text-align: center;
}

#order2 {
  order: 2;
}

/* Editor */

.summernote > :first-child {
  min-height: 250px;
  border: 1px solid #a9a9a9;
  border-color: #f0f1f5;
}
.rdw-image-modal {
  right: 0 !important;
  left: unset !important;
}
.rdw-editor-toolbar {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

/* Button */
button:focus,
a:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* Chart */

.go2478912419 {
  text-align: center;
  display: block;
  margin-bottom: 2rem;
}

.picker-input__text,
.range-picker-input {
  width: 100% !important;
}

.timepicker-container,
.MuiFormControl-root.MuiTextField-root {
  flex: 1 1 auto;
}

.timepicker-container input {
  background: #fff;
  border: 1px solid #f0f1f5;
  color: #b1b1b1;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  height: 41px;
  width: 100%;
  box-sizing: border-box;
}

.MuiFormControl-root.MuiTextField-root {
  display: block;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  width: 100%;
  height: 100%;
}

.dd .nestable-item-name {
  color: #fff;
  background: #f93a0b;
  display: block;
  height: 35px;
  margin: 8px 0;
  padding: 8px 10px;
  text-decoration: none;
  font-weight: 700;
  border: 1px solid #f93a0b;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 13px;
  margin-bottom: -4px;
}
.dd .nestable-item-name span {
  margin-right: 5px;
}
/* Picker */

.i-false:after {
  content: none !important;
}

rect#chart_ChartBorder {
  fill: transparent;
}

/* pagination */
.pagination {
  margin-bottom: 0 !important;
}
@import url("https://fonts.googleapis.com/css?family=Lato");
.rc-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 80;
}
.rc-backdrop.invert {
  background: rgba(1, 1, 1, 0.7);
}
.range-picker-input {
  display: inline-flex;
  border: 1px solid #e4e4e4;
  width: 300px;
}
.range-picker-input * {
  box-sizing: border-box;
}
.range-picker-input__icon {
  display: inline-flex;
  align-items: center;
}
.range-picker-input__start,
.range-picker-input__end {
  display: inline-flex;
  flex: 1;
}
.range-picker-input__start .picker-input.range input,
.range-picker-input__end .picker-input.range input {
  width: 100%;
  border: none;
}
.picker-input {
  display: inline-block;
  position: relative;
}
.picker-input__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  display: inline-flex;
  align-items: center;
}
.picker-input__text {
  padding: 10px;
  border: 1px solid #e4e4e4;
  outline: none;
  font-size: 16.8px;
}
.picker-input__text:disabled {
  background: #e4e4e4;
}
.picker-input__clear {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
}
.picker {
  display: inline-block;
}
.picker__container {
  position: absolute;
  z-index: 100;
}
.picker__container.portal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.picker__container__include-time {
  border: 1px solid #e4e4e4;
}
.picker__container__include-time .calendar__item,
.picker__container__include-time .time__container {
  border: none;
}
.picker__container__tab {
  margin: 10px 0;
}
.picker__container__tab button {
  padding: 5px 10px;
  outline: none;
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  border-bottom: 2px solid #e4e4e4;
}
.picker__container__tab button.active {
  color: #49599a;
  border-bottom: 2px solid #49599a;
}
.picker__container__tab button:first-child {
  border-right: none;
}
.picker__container__tab button svg {
  margin-right: 5px;
}
.time__container {
  display: inline-flex;
  align-items: center;
  border: 1px solid #e4e4e4;
  padding: 15px;
  background: white;
  font-family: "Lato";
  font-size: 12px;
}
.time__container__div {
  margin: 0 10px;
}
.time__container__type {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.time-input {
  display: inline-block;
  width: 40px;
  overflow: hidden;
}
.time-input__up,
.time-input__down {
  border: 1px solid #e4e4e4;
}
.time-input__up button,
.time-input__down button {
  outline: none;
  width: 100%;
  cursor: pointer;
  border: none;
}
.time-input__text {
  width: 100%;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  box-sizing: border-box;
}
.time-input__text input {
  width: 100%;
  box-sizing: border-box;
  border: none;
  font-size: 15px;
  padding: 5px;
  text-align: center;
  outline: none;
}

/* calender */

.react-calendar {
  border: none !important;
  font-family: "poppins", sans-serif;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: none !important;
}
button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  color: #dee2e6 !important;
  font-family: "poppins", sans-serif;
}

span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  font-family: "poppins", sans-serif;
}
button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  display: none;
}

button.react-calendar__tile.react-calendar__month-view__days__day {
  border-radius: 10px;
  color: black;
  color: #000;
  font-weight: 500;
  height: 46px;
  line-height: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "poppins", sans-serif;
}
button.react-calendar__navigation__label {
  /* padding: 20px 15px; */
  border-radius: 1.75rem !important;
  font-size: 20px;
}
button.react-calendar__navigation__label:hover {
  color: white !important;
  background: #eeeeee !important;
}
button.react-calendar__navigation__label:hover > span {
  /* color: white !important; */
  /* background: #EEEEEE !important; */
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus,
.react-calendar__navigation__label:hover > span {
  /* background-color: #eeeeee !important; */
  /* color: white; */
  /* border-radius: 50%; */
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar__tile--hasActive,
.react-calendar__navigation button[disabled],
.react-calendar__navigation button[disabled] > span {
  background-color: #eeeeee !important;

  /* color: white; */
}
button.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day,
button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  position: relative;
  background: #eeeeee !important;
  box-shadow: none;
  /* color: white !important; */
  border-radius: 10px;
  overflow: unset !important;
  padding: 16px 5px 15px 5px;
  /* border-radius: 35px; */
  line-height: 1;
}

.react-calendar {
  border: none;
}

.calendar {
  display: inline-block;
  background: white;
  font-size: 12px;
}
.calendar *,
.calendar *:before,
.calendar *:after {
  box-sizing: border-box;
}
.calendar__container {
  width: 270px;
  font-family: "Roboto", sans-serif;
  display: none;
}
.calendar__list {
  display: table;
}
.calendar__item {
  display: table-cell;
  border: 1px solid #ececec;
}
.calendar__item:not(:first-child) {
  border-left: none !important;
}
.calendar--show {
  display: inline-block;
}
.calendar__head {
  position: relative;
  background: transparent;
  padding: 10px 6px;
}
.calendar__head--title {
  font-size: 1.3em;
  color: #9a9fa4;
  text-align: center;
  margin: 4px;
}
.calendar__head--button {
  outline: none;
  border: none;
  cursor: pointer;
  background: none;
  font-size: 20px;
}
.calendar__head--button svg {
  fill: #9a9fa4;
}
.calendar__head--prev,
.calendar__head--next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.calendar__head--prev {
  left: 0;
}
.calendar__head--next {
  right: 0;
}
.calendar__panel--show {
  display: block !important;
}
.calendar__panel--today {
  background: #aab6fe;
  padding: 5px;
  display: none;
}
.calendar__panel--today h2 {
  margin: 0;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
}
.calendar__body--table {
  width: 100%;
  table-layout: fixed;
  text-align: center;
  border-spacing: none;
  border-collapse: collapse;
}
.calendar__body--table th {
  height: 30px;
  vertical-align: middle;
  color: #9a9fa4;
}
.calendar__day {
  vertical-align: top;
  padding-top: 5px;
  height: 40px;
  cursor: pointer;
}
/* .calendar__day self:hover:not(&--disabled) {
   background: #aab6fe;
   cursor: pointer;
} */
.calendar__day--0 {
  color: red;
}
.calendar__day--6 {
  color: blue;
}
.calendar__day--today {
  background: #fff9c4;
}
.calendar__day--disabled {
  color: #ddd;
  cursor: initial;
}
.calendar__day--start,
.calendar__day--end,
.calendar__day--selected {
  background: #7986cb;
  color: #fff;
}
.calendar__day--start:hover,
.calendar__day--end:hover,
.calendar__day--selected:hover {
  background: #7986cb;
}
.calendar__day--range {
  background: #dce1ff;
}
.calendar__day--text {
  display: block;
  font-size: 10px;
}
.calendar__year,
.calendar__month {
  height: 55px;
  vertical-align: middle;
}
.calendar__year:hover,
.calendar__month:hover {
  background: #aab6fe;
  cursor: pointer;
}

/* Selector Design */
.css-1okebmr-indicatorSeparator {
  display: none;
}

.nestable .icon-minus-gray,
.nestable .icon-plus-gray {
  background-image: none;
}
.nestable .nestable-icon:before {
  content: "\f068";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  width: auto;
  height: auto;
  display: contents;
}
.nestable .nestable-item--children-collapsed .nestable-icon:before {
  content: "\f067";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  width: auto;
  height: auto;
  display: contents;
}

.hover-underline:hover {
  text-decoration: underline;
}

.text-blue-500 {
  color: #3b82f6; /* Tailwind's blue-500 color */
}

.bg-red-300 {
  background-color: #fca5a5; /* Tailwind's bg-red-300 equivalent */
}

.text-red-900 {
  color: #7f1d1d; /* Tailwind's text-red-900 equivalent */
}

.bg-green-300 {
  background-color: #a7f3d0; /* Tailwind's bg-green-300 equivalent */
}

.text-green-900 {
  color: #064e3b; /* Tailwind's text-green-900 equivalent */
}

.fa-eye-icon {
  right: 9px;
  top: 2.3rem;
}

.custom-bg-opacity-75 {
  background-color: rgba(255, 255, 255, 0.75);
}

.custom-spinner {
  width: 2rem; /* 8 * 0.25rem */
  height: 2rem; /* 8 * 0.25rem */
  border-width: 0.25rem;
  border-color: #cc2c05; /* Tailwind's border-blue-500 */
  border-top-color: transparent;
  border-style: solid;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-width-height-24px {
  width: 24px;
  height: 24px;
}

.text-xs {
  font-size: 0.75rem; /* equivalent to Tailwind's text-xs */
}

.custom-circle {
  transition: stroke-dashoffset 1s linear;
}

.custom-bg-opacity-60 {
  background-color: rgba(0, 0, 0, 0.6);
}

.custom-z-50 {
  z-index: 50;
}

.custom-max-w-md {
  max-width: 28rem; /* equivalent to Tailwind's max-w-md */
}

.p-6 {
  padding: 1.5rem; /* equivalent to Tailwind's p-6 */
}

.custom-text-gray-400 {
  color: #9ca3af; /* equivalent to Tailwind's text-gray-400 */
}

.custom-hover-bg-gray-200:hover {
  background-color: #e5e7eb; /* equivalent to Tailwind's hover:bg-gray-200 */
}

.custom-hover-text-gray-900:hover {
  color: #111827; /* equivalent to Tailwind's hover:text-gray-900 */
}

.custom-dark-hover-bg-gray-600:hover {
  background-color: #4b5563; /* equivalent to Tailwind's dark:hover:bg-gray-600 */
}

.custom-dark-hover-text-white:hover {
  color: #ffffff; /* equivalent to Tailwind's dark:hover:text-white */
}

.custom-w-5 {
  width: 1.25rem; /* equivalent to Tailwind's w-5 */
}

.custom-h-5 {
  height: 1.25rem; /* equivalent to Tailwind's h-5 */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate-fadeIn {
  /* animation: fadeIn 1.5s ease-in-out; */
}

.bar-chart-container {
  width: 100%;
  height: 250px;
  background: linear-gradient(
    135deg,
    rgba(96, 165, 250, 0.1),
    rgba(59, 130, 246, 0.1)
  );
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}

.content-card {
  background-color: #ffffff;
  padding: 1.5rem 2rem;
  border-radius: 15px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  position: relative;
}

.content-card:hover {
  transform: translateY(-3px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.content-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background-color: #3b82f6;
  border-radius: 15px 0 0 15px;
}

.section-header {
  font-size: 2.5rem;
  font-weight: 800;
  color: #1f2937;
  margin-bottom: 2rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  position: relative;
}

.section-header::after {
  content: "";
  width: 80px;
  height: 4px;
  background-color: #3b82f6;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.content-title {
  font-weight: 700;
  color: #1f2937;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.bullet-list {
  padding-left: 1.5rem;
  margin-top: 0.5rem;
}

.bullet-list li {
  list-style-type: disc !important; /* Ensure bullet points are of type 'disc' */
  list-style-position: inside !important; /* Make sure bullets are inside the list item */
  margin-bottom: 0.5rem;
}

.bg-sub-section {
  background-color: rgb(248, 249, 250);
}
.btn-outline-primary.custom-disabled {
  cursor: not-allowed;
  opacity: 0.7 !important;
  color: var(--rgba-primary-9) !important;
}

#personality-test .accordion-button {
  color: #fff;
  border: none;
}
#personality-test .accordion-button::after {
  color: #fff;
}
.table-layout-fixed {
  table-layout: fixed;
}
.table-layout-custom {
  table-layout: fixed;
}
.custom-phone-field .form-control:first-child {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  margin-right: -1px;
}
.custom-phone-field input[type="text"] {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.custom-scrollable-menu {
  max-height: 200px !important;
  min-width: 350px !important;
  overflow-y: auto !important;
}
#country-code-search{
  border-radius: 6px !important;
}

@media (max-width: 576px) {
  .table-layout-custom {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

/* Text Selection Styles */
::selection {
  background-color: rgba(167, 139, 250, 0.2); /* Increased opacity for more visible violet */
  color: #000;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
}

::-moz-selection {
  background-color: rgba(167, 139, 250, 0.2); /* Increased opacity for more visible violet */
  color: #000;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
}
