.modal-body-custom {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 1rem 2rem 1rem;
}

.modal-body-tip {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid var(--primary);
  background-color: var(--rgba-primary-1);
  margin-bottom: 0.5rem;
}

.modal-body-tip-title {
  font-size: 1rem;
  font-weight: bolder;
  color: var(--primary);
}

.modal-body-tip-description {
  color: var(--primary);
  font-size: 1rem;
}

.modal-body-subtitle {
  font-size: 1.25rem;
  margin: 0;
}
.modal-body-subtitle-desc {
  color: black;
  font-size: 1.1rem;
  margin: 0;
  line-height: 1.8rem;
}
.divider {
  width: 100%;
  border-bottom: 1px solid gainsboro;
  margin: 1rem 0rem;
}
.modal-body-tips-title {
  font-size: 1.25rem;
  margin: 0;
}
.modal-body-tips-list {
  box-sizing: border-box;
  /* display: flex;
    flex-direction: column;
    gap: 1rem; */
}
.modal-body-tips-list-item {
  color: black;
  font-size: 1.1rem;
  padding: 8px 0px;
  margin: 0 0 0 1.1rem;
}
.no-margin {
  margin: 0 !important;
}

/* overriding class name for model-content */
.modal-content {
  margin-top: 77px;
}
