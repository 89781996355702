:root {
  --nav-headbg: #ffffff;
  --sidebar-bg: #ffffff;
  --headerbg: #ffffff;
}

//background for nav header
@each $name, $color in $theme_backgrounds {
  [data-nav-headerbg="#{$name}"][data-theme-version="dark"],
  [data-nav-headerbg="#{$name}"] {
    @if $name != "color_1" {
      --nav-headbg: #{$color};
      .nav-header {
        .brand-logo {
          .brand-title {
            path {
              fill: $white;
            }
            tspan {
              fill: $white;
            }
          }
        }
        .hamburger .line {
          background: $black;
        }
      }
      .nav-header .rect-primary-rect {
        fill: #ffffff;
      }
      .nav-header {
        .logo-abbr {
          path {
            fill: #f93a0b;
          }
        }
      }

      //special work for color
      &[data-nav-headerbg="color_8"] {
        .nav-header {
          .brand-logo {
            .brand-title {
              path {
                fill: $black;
              }
              tspan {
                fill: $black;
              }
            }
          }
          .hamburger .line {
            background: $black;
          }
        }
      }
    }
  }
}
//background for nav header
@each $name, $color in $theme_backgrounds {
  [data-sibebarbg="#{$name}"][data-theme-version="dark"],
  [data-sibebarbg="#{$name}"] {
    @if $name != "color_1" {
      --sidebar-bg: #{$color};

      /* .hamburger .line{
				background:$white;
			} */
      .menu-toggle .dlabnav .metismenu li > ul {
        background: $color !important;
      }
      .dlabnav .metismenu ul a:before {
        background: rgba($white, 0.5);
      }

      &[data-sidebar-style="full"][data-layout="vertical"] {
        .dlabnav {
          .metismenu {
            & > li {
              &.mm-active {
                & > a {
                  i {
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
      &[data-sidebar-style="mini"],
      &[data-sidebar-style="modern"],
      &[data-sidebar-style="compact"],
      &[data-sidebar-style="icon-hover"][data-layout="horizontal"],
      &[data-sidebar-style="full"][data-layout="horizontal"] {
        .dlabnav {
          .metismenu li ul {
            background-color: lighten($color: $color, $amount: 10%);
          }
        }
        .dlabnav .metismenu > li.mm-active > a,
        .dlabnav .metismenu > li:hover > a {
          background: rgba($white, 0.15) !important;
        }
      }
      /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.dlabnav .metismenu > li.mm-active > a,
				.dlabnav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
      &[data-sidebar-style="compact"][data-layout="vertical"] {
        .dlabnav {
          .metismenu {
            & > li {
              a {
                &:hover {
                  color: $white;
                }
              }
              & > a {
                & > i {
                  background: lighten($color: $color, $amount: 3%) !important;
                  color: rgba($white, 0.7);
                }
              }
              &.mm-active,
              &:hover {
                & > a {
                  box-shadow: none;
                  background: transparent !important;
                  color: $white !important;
                  i {
                    background: lighten(
                      $color: $color,
                      $amount: 10%
                    ) !important;
                    color: $white !important;
                  }
                }
              }
            }
          }
        }
      }
      .dlabnav {
        .metismenu {
          a {
            color: rgba($white, 0.8) !important;
          }
          li {
            ul {
              a {
                &:hover,
                &:focus,
                &.mm-active {
                  color: $white;
                }
              }
            }
          }
          & > li {
            & > a {
              color: rgba($white, 0.85) !important;
              i {
                color: rgba($white, 0.85) !important;
              }
            }
            &.mm-active {
              & > a {
                background: rgba($white, 0.15) !important;
                color: $white !important;
                i {
                  color: $white !important;
                }
                &:before {
                  background: $white !important;
                }
              }
            }
          }
          .has-arrow:after {
            border-color: rgba($white, 0.85)
              transparent
              transparent
              rgba($white, 0.85) !important;
          }
        }
        .header-info2 span {
          color: $white;
        }
        .sidebar-info {
          color: $white;
          i {
            color: $white !important;
          }
        }
      }
      .plus-box {
        background-color: lighten($color: $color, $amount: 10%);
      }
      .copyright {
        color: $white;
        strong {
          color: $white;
        }
      }
      //special work for color
      &[data-sibebarbg="color_8"] {
        .dlabnav .metismenu ul a:before {
          background: rgba($black, 0.5);
        }
        .dlabnav {
          .metismenu {
            a {
              color: rgba($black, 0.6) !important;
              &:hover,
              &:focus,
              &.mm-active {
                color: $black !important;
              }
            }
            .has-arrow:after {
              border-color: rgba(0, 0, 0, 0.85)
                transparent
                transparent
                rgba(0, 0, 0, 0.85);
            }
            & > li {
              & > a {
                color: rgba($black, 0.6) !important;
                i {
                  color: rgba($black, 0.6) !important;
                }
              }
              &.mm-active {
                & > a {
                  background: rgba($black, 0.6) !important;
                  &:before {
                    background: rgba($black, 0.6) !important;
                  }
                }
              }
            }
          }
          .copyright p {
            color: rgba($black, 0.6);
          }
          .book-box {
            background: rgba($black, 0.4);
          }
          .header-info2 span {
            color: rgba($black, 0.6);
          }
          .sidebar-info {
            color: rgba($black, 0.6);
            i {
              color: rgba($black, 0.6);
            }
          }
        }

        .plus-box {
          background-color: rgba($black, 0.4);
        }
      }
    }
  }
}

//background for nav header
@each $name, $color in $theme_backgrounds {
  [data-headerbg="#{$name}"][data-theme-version="dark"],
  [data-headerbg="#{$name}"] {
    @if $name != "color_1" {
      --headerbg: #{$color};
      .search-area .input-group-text,
      .search-area .form-control,
      .search-area .form-control::placeholder {
        color: $white;
      }
      .search-area .input-group-append .input-group-text i {
        color: $white;
      }
      .header-left {
        .search-area .form-control,
        .search-area .input-group-text {
          background-color: lighten($color: $color, $amount: 10%);
          i {
            color: $white;
          }
        }
        .dashboard_bar {
          color: $white;
        }
        .plus-icon a {
          background-color: lighten($color: $color, $amount: 10%);
        }
      }
      .header-right {
        svg {
          path {
            fill: $white;
          }
        }
        #icon-dark {
          color: $white;
        }
      }
      .header-profile > a.nav-link .header-info small,
      .header-profile > a.nav-link .header-info span {
        color: $white;
      }
      .hamburger .line {
        background: $white !important;
      }
      //special work for color
      &[data-headerbg="color_8"] {
        .search-area .input-group-text,
        .search-area .form-control,
        .search-area .form-control::placeholder {
          color: $black;
          background: #f1f1f1;
        }
        .header-left {
          .search-area .form-control,
          .search-area .input-group-text {
            i {
              color: $black;
            }
          }
          .dashboard_bar {
            color: $black;
          }
          .plus-icon a {
            background-color: $black;
          }
        }
        .hamburger .line {
          background: $black;
        }

        .header-right {
          svg {
            path {
              fill: rgba($black, 0.4);
            }
          }
        }
      }
    }
  }
}
