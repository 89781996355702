.blockUI.blockMsg.blockPage {
  border: 0 !important;
}

#loginForm {
  cursor: auto;
}

.blockMsg {
  border: 0rem;
  width: 20% !important;
  h1 {
    font-size: 1rem;
    padding: 0.5rem 0;
    margin-bottom: 0;
  }
}
