//heart Blast Effect
span.required {
  color: $danger;
}
.heart-blast {
  background-position: -105rem 0 !important;
  transition: background 1s steps(28);
  @include respond("phone") {
    background-position: -103.2rem 0 !important;
  }
}

.heart-blast {
  background-position: -1680px 0 !important;
  transition: background 1s steps(28);
}

/* ------Dashboard------ */

#RecentActivityContent {
  height: 400px;
  overflow-y: scroll;
}

.plus-box {
  background: $secondary;
  color: #fff;
  font-size: 0.875rem;
  padding: 2rem 2rem;
  overflow: hidden;
  margin: 0 1rem;
  border-radius: 1.125rem;
  align-items: center;
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
  position: relative;
  &:before {
    position: absolute;
    content: "";
    top: 1.2rem;
    left: 1rem;
    background-image: url(../images/dots1.png);
    background-repeat: no-repeat;
    height: 3rem;
    width: 3rem;
  }
  @include respond("laptop") {
    margin: 1.25rem 1.25rem;
  }
  .plus-box-p {
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.5);
  }
}
.plus-icon {
  a {
    height: 3rem;
    width: 3rem;
    background: $secondary;
    display: block;
    border-radius: $radius;
    line-height: 3rem;
    margin-left: 1rem;
    font-size: 1.2rem;
    text-align: center;
    color: $white;
    @include respond("tab-land") {
      height: 2rem;
      width: 2rem;
      line-height: 2rem;
      font-size: 1rem;
    }
    @include respond("phone") {
      margin-left: 0;
    }
  }
  @include respond("phone") {
    display: none;
  }
}

.action-buttons .btn {
  padding: 0;
  border: 0;
  margin: 0 4px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
}

.custom-label {
  min-width: 120px;
  display: inline-block;
  color: $black;
}
.custom-label-2 {
  min-width: 180px;
  display: inline-block;
  color: $black;
  @include respond("phone") {
    min-width: 140px;
  }
}

.separate-row {
  & > div {
    border-bottom: 1px solid #eee;
    &:nth-child(n + 3) {
      border-bottom: 0;
    }
    &:nth-child(odd) {
      border-right: 1px solid #eee;
    }
    @include respond("phone") {
      &:nth-child(n + 3) {
        border-bottom: 1px solid #eee;
      }
      &:nth-child(odd) {
        border-right: 0;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
.coin-tabs {
  background: #f9f9f9;
  border-radius: $radius;
  .nav-tabs {
    border: 0;
    border-radius: $radius;
    background-color: var(--rgba-primary-1);
    .nav-item {
      .nav-link {
        border: 0;
        border-radius: $radius;
        font-size: 1rem;
        padding: 0.5rem 1.125rem;
        &.active {
          background: var(--primary);
          color: $white;

          &:hover {
            color: $white;
          }
        }
        &:hover {
          color: var(--primary);
        }
      }
    }
    @include respond("phone") {
      .nav-item {
        flex: 1;
        .nav-link {
          font-size: 0.875rem;
          padding: 0.5rem 0.8rem;
        }
      }
    }
  }
}

.update-profile {
  img {
    height: 7rem;
    width: 7rem;
    border-radius: 1.25rem;
    @include respond("tab-land") {
      height: 5rem;
      width: 5rem;
    }
  }
}
.default-progress {
  height: 13px;
}
.bg-green {
  background: #1d92df !important;
}
.dashboard-select,
.dashboard-select-1,
.dashboard-select-2 {
  border-radius: $radius;
  height: 47px;
  border: 0;
  width: 150px !important;
  line-height: 44px;
  color: var(--primary);
  text-transform: capitalize;

  @include respond("laptop") {
    width: 100px !important;
  }
  .btn {
    background-color: var(--rgba-primary-1) !important;
    color: var(--primary) !important;
    border-color: var(--rgba-primary-1) !important;
  }
  .dropdown-menu {
    .dropdown-item {
      padding: 8px 20px;
      font-size: 14px;
      line-height: 2;
      &.active {
        background-color: var(--primary);
        color: $white;
      }
    }
  }
}

.dashboard-select-1,
.dashboard-select-2 {
  width: 235px !important;
  background: transparent;
  text-transform: capitalize;
  .btn {
    color: var(--primary) !important;
    border: 0 !important;
    background-color: transparent !important;
  }
}
.dashboard-select-2 {
  width: 100% !important;
  border: 1px solid $border !important;
  @include custommq($max: 1600px) {
    height: 42px !important;
    line-height: 2.7rem;
  }
  @include respond("laptop") {
    height: 39px !important;
  }

  @include respond("laptop") {
    height: 2.8rem;
    line-height: 2.9rem;
  }
  @include respond("phone-land") {
    line-height: 2.6rem;
  }
}

.recent-activity {
  position: relative;
  padding-bottom: 1rem;

  &:after {
    position: absolute;
    content: "";
    top: 1.188rem;
    left: 0.5rem;
    width: 0.063rem;
    height: 100%;
    background: #e4e4e4;
  }
  @include respond("phone") {
    img {
      height: 3.75rem;
      width: 3.75rem;
    }
  }
}
.jobs {
  border: 1px solid #b1c1c8;
  padding: 1.5rem 1.5rem;
  border-radius: $radius;
  i {
    color: var(--primary);
  }
}
.default-select.style-1 {
  border-radius: 2rem;
  line-height: 2rem;
  background: transparent;
  color: var(--primary);
  font-size: 1rem;
  font-weight: 500;
  &:after {
    border-color: var(--primary);
  }
}
//application page

table.dataTable tbody th,
table.dataTable tbody td {
  padding: 1.25rem 0.625rem;
}

.Studios-info {
  margin-right: 2rem;

  @include respond("phone") {
    margin-right: 1rem;
  }

  @include respond("desktop") {
    margin-right: 1rem;
    svg {
      width: 6rem;
    }
  }
  @include respond("phone") {
    margin-bottom: 1rem;
    svg {
      width: 5rem;
    }
  }
}
.application {
  margin-left: 2rem;
  @include respond("phone") {
    margin-left: 0.8rem;
  }
}
.wspace-no {
  white-space: nowrap;
}

.user {
  img {
    height: 6.25rem;
    width: 6.25rem;
    border-radius: $radius;
  }
  @include respond("phone") {
    img {
      height: 5rem;
      width: 5rem;
      float: left;
      margin-right: 15px;
    }
  }
}

.recent-activity-wrapper {
  .recent-activity:last-child {
    &:after {
      content: none;
    }
  }
}

.listline-wrapper {
  display: flex;
  flex-wrap: wrap;
  .item:not(:last-child) {
    border-right: 1px solid $border-color;
    margin-right: 10px;
    padding: 2px 0;
    padding-right: 10px;
  }
  .item {
    display: flex;
    align-items: center;
    svg {
      padding-right: 8px;
    }
    i {
      color: #a0a0a0;
      padding-right: 8px;
    }
  }
}
.svg-main-icon g [fill] {
  fill: var(--primary);
}
.card {
  &.product-grid-card {
    .card-body {
      padding: 15px;
    }
  }
}
.sweet-image {
  width: 20%;
}

.dlab-scroll {
  overflow-y: scroll;
}

.dlab-scroll {
  overflow-y: scroll;
}
body > * {
  // overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: rgba(111, 133, 147, 0.1) #fff0;
}
::-webkit-scrollbar {
  width: 5px;
  opacity: 0;
}
/* ::-webkit-scrollbar-thumb{
	background:  rgba(111, 133, 147, 0.0); 
} */
::-webkit-scrollbar-thumb {
  background: rgba(111, 133, 147, 0.1);
}

.clockpicker-popover {
  position: absolute;
}

.modal-dialog-center {
  min-height: calc(100% - 3.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
}
.nice-select {
  &:after {
    border-color: #c2c2c2;
  }
}
.table {
  &.application {
    span {
      white-space: nowrap;
    }
  }
}
.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active {
  border-color: transparent !important;
}
input.form-control {
  position: relative;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.exp-del {
  i,
  strong {
    color: #393939;
  }
}
.order-table {
  th,
  td {
    &:first-child {
      padding-left: 14px;
    }
  }
}
.comp-tabs {
  .grid,
  .line {
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    border-radius: $radius;
  }
  .grid {
    background-color: var(--primary);
    svg {
      width: 1rem;
      height: 1rem;
      path {
        fill: $white;
      }
    }
  }
  .line {
    background-color: var(--rgba-primary-1);
    svg {
      width: 1rem;
      height: 1rem;
      path {
        fill: var(--primary);
      }
    }
  }
}
.calendar-table {
  thead {
    tr {
      &:last-child {
        th {
          font-size: 15px;
        }
      }
    }
  }
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  color: $white;
}
.form-control.default-select {
  border-radius: $radius;
  .list {
    width: 100%;
    padding: 10px 0;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);

    .option {
      padding: 8px 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 2;
      color: $black;
    }

    .option.selected.focus {
      background-color: var(--primary);
      color: $white;
      padding: 8px 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 2;
    }
    &:hover {
      .option.selected.focus {
        background-color: var(--primary) !important;
      }
    }
  }
  &:after {
    border-width: 2px;
  }
}
.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  color: $white;
}
.overflow-unset {
  overflow: unset;
}

.image-placeholder {
  position: relative;
  max-width: 130px;
  margin: 10px auto;
  .avatar-edit {
    position: absolute;
    right: -10px;
    z-index: 1;
    top: 0px;
    input {
      display: none;
      + label {
        display: inline-block;
        width: 34px;
        height: 34px;
        margin-bottom: 0;
        border-radius: 100%;
        background: #ffffff;
        border: 1px solid transparent;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
        font-weight: normal;
        transition: all 0.2s ease-in-out;
        &:hover {
          background: #f1f1f1;
          border-color: #d6d6d6;
        }
        &:after {
          content: "\f303";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          color: #757575;
          position: absolute;
          top: 6px;
          left: 0;
          right: 0;
          text-align: center;
          margin: auto;
        }
      }
    }
  }
  .avatar-preview {
    width: 130px;
    height: 95px;
    position: relative;
    border-radius: 30px;
    border: 3px solid #f8f8f8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    & > div {
      width: 100%;
      height: 100%;
      border-radius: 30px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
.project-boxed {
  overflow: hidden;
}

.custom-react-select {
  & > div:nth-child(4) {
    & > div {
      background-color: #fff;
      & > div {
        background-color: #fff;
        cursor: pointer;
        @include transitionMedium;
        &:hover {
          background-color: var(--rgba-primary-1);
          color: #000;
        }
      }
    }
  }
  & > div {
    &:nth-child(3) {
      height: 3rem;
      background-color: #fff;
      border-radius: 0.5rem;
      border-color: #e9e2f8 !important;
      //box-shadow: 0px 7px 15px 0px rgba(61, 70, 83, 0.1)!important;
      @include respond("laptop") {
        height: 2.5rem;
      }
    }
    &:nth-child(4) {
      border-radius: 10px;
      min-width: 150px;
      //border: 2px solid var(--primary);
      overflow: hidden;
      box-shadow: 0px 10px 40px 0px rgba(21, 50, 93, 0.1);
    }
    & > div {
      &:first-child {
        div {
          font-size: 0.875rem;
          color: #6e6e6e;
        }
      }
      &:nth-child(2) {
        & > span {
          display: none;
        }
      }
    }
  }
}

.swal2-image {
  max-width: 20%;
}
.dropdown {
  .dropdown-toggle {
    cursor: pointer;
  }
}
.featured-scroll {
  &.dlab-scroll {
    overflow-x: hidden;
  }
}
