[data-theme-version="dark"] {
  .card {
    background-color: $dark-card;
    box-shadow: none;
  }
  .dropdown-menu {
    background-color: $dark-card;
    box-shadow: 0rem 0rem 0rem 0.0625rem rgba(255, 255, 255, 0.1);
    .dropdown-item {
      color: #777777;
      &.selected,
      &.selected.active,
      &.active,
      &:hover,
      &:focus {
        background-color: $dark-card;
        color: $white;
      }
    }
  }
  a {
    color: $white;
  }
  .btn-link g [fill] {
    fill: #fff;
  }
  .swal2-popup #swal2-content {
    color: #fff;
  }
  .btn-light:active,
  .btn-light:focus,
  .btn-light:hover {
    color: #000;
  }
  .form-control {
    background-color: $d-bg;
    border-color: $d-border !important;
    color: $white;
  }
  .modal-content {
    background: $dark-card;
  }
  .modal-footer,
  .modal-header {
    border-color: $d-border;
  }
  .dashboard-select-2,
  .menu-tabs {
    border-color: $d-border !important;
  }
  .dd-handle.move-media + .accordion-button {
    background-color: $d-bg;
  }
  .close {
    font-weight: 400;
    color: $white;
    text-shadow: none;

    &:hover {
      color: $white;
    }
  }
  .star-rating .product-review,
  .new-arrival-content .item {
    color: $white;
  }
  .custom-dropdown .dropdown-menu {
    border-color: $d-border;
  }
  .widget-stat .media > span {
    background: $d-border;
    border-color: $d-border;
    color: $white;
  }
  .plus-minus-input .custom-btn {
    background: $d-bg;
    border-color: $d-border;
  }
  .dropdown-divider,
  .size-filter ul li {
    border-color: $d-border;
  }
  .custom-select {
    border-color: $d-border;
    color: $d-ctl;
    background: $d-bg;
  }
  .nav-tabs {
    border-color: $d-border !important;
  }
  .mail-list .list-group-item.active i {
    color: $white;
  }
  hr {
    border-color: $d-border;
  }
  .grid-col {
    background: $d-bg;
  }

  .noUi-target {
    border-color: $d-border;
    border-radius: 0.5rem;
    box-shadow: none;

    .noUi-connects {
      background: $d-border;
    }
  }
  .noUi-marker-large,
  .noUi-marker {
    background: $d-border;
  }
  .input-group-text {
    background: $dark-card;
    color: $white;
    border-color: $d-border;
  }
  .note-editor.note-frame {
    border-color: $d-border;
    .btn {
      color: $white;
    }
    .note-editing-area .note-editable {
      color: $white;
    }
  }
  .widget-media .timeline .timeline-panel {
    border-color: $d-border;
  }
  .notification_dropdown .dropdown-menu-right .all-notification {
    border-color: $d-border;
  }
  #user-activity .nav-tabs .nav-link {
    border-color: $d-border;
    &.active {
      background: $d-bg;
      color: $white;
    }
  }
  .list-group-item-action {
    color: $border;
  }
  .list-group-item-action:focus,
  .list-group-item-action:hover,
  .list-group-item-action:focus {
    background-color: $d-bg;
    border-color: $d-bg;
  }
  .list-group-item.active {
    color: $white;
    border-color: var(--primary);
    &:focus,
    &:hover,
    &:focus {
      background-color: var(--primary);
      border-color: var(--primary);
      color: $white;
    }
  }
  .swal2-popup {
    background: $dark-card;
  }
  .form-head .btn-outline-primary {
    border-color: $d-border;
  }
  .form-head .btn-outline-primary:hover {
    border-color: var(--primary);
  }
  .review-tab.nav-pills li a.nav-link.active {
    background: transparent;
  }
  .new-arrival-content {
    h4 {
      a {
        color: $white;
      }
    }
  }
  .text-black {
    color: $white !important;
  }
  .abilities-chart .ct-chart .ct-label {
    fill: $white;
  }
  .morris_chart_height text tspan {
    fill: $white;
  }
  .btn-link {
    color: $white;
  }
  .order-bg {
    background: $d-bg;
  }
  .detault-daterange {
    background: $d-bg;
    color: $white;
    .input-group-text {
      background: $dark-card;
      border: 0;
    }
  }
  .dataTablesCard {
    background-color: $dark-card;
  }
  .compose-content .dropzone {
    background: $d-bg !important;
    border-color: $d-border;
    .dlab-message .dlab-button {
      color: $white;
    }
  }
  .daterangepicker {
    background: $d-bg;
    border-color: $d-border;
    .calendar-table {
      border-color: $d-border;
      background: $d-bg;
      .table-condensed {
        td {
          &:hover {
            background-color: var(--primary);
            color: $white;
          }
        }
      }
    }
    &:after {
      border-bottom: 0.375rem solid $d-bg;
    }
  }
  .daterangepicker .drp-buttons {
    border-color: $d-border;
  }

  .daterangepicker select.hourselect,
  .daterangepicker select.minuteselect,
  .daterangepicker select.secondselect,
  .daterangepicker select.ampmselect {
    background: $d-bg;
    border: 0.0625rem solid $d-border;
    color: $white;
  }

  .daterangepicker td.off,
  .daterangepicker td.off.in-range,
  .daterangepicker td.off.start-date,
  .daterangepicker td.off.end-date {
    background-color: $dark-card;
    &:hover {
      background-color: var(--primary);
      color: $white;
    }
  }
  .app-fullcalendar {
    .fc-button {
      background-color: $d-bg;
      border-color: var(--primary);
      color: $white;
      text-shadow: none;
      &:hover,
      &.fc-stat-hover {
        background-color: var(--primary);
      }
    }
  }
  .swal2-popup .swal2-styled:focus {
    outline: 0;
    box-shadow:
      0 0 0 0.125rem $d-border,
      0 0 0 0.25rem var(--rgba-primary-1);
  }
  .dd-handle {
    border-color: $d-border;
  }
  .menu-toggle .dlabnav .metismenu li > ul {
    background: $dark-card;
  }
  .header-right .notification_dropdown .nav-link {
    border-color: $d-border;
  }
  .nav-tabs .nav-link {
    &:hover,
    &.active {
      border-color: var(--primary);
      background: transparent;
      color: white !important;
    }
  }
  .clockpicker-popover .popover-content {
    background-color: $dark-card;
  }
  .clockpicker-plate {
    background-color: $d-bg;
  }
  .clockpicker-popover .popover-title {
    background-color: $d-bg;
    color: $white;
  }
  .form-wizard .nav-wizard li .nav-link span {
    background-color: $d-bg;
  }
  .form-wizard .nav-wizard li .nav-link:after {
    background: $d-bg;
  }
  .form-wizard .nav-wizard li .nav-link.active:after,
  .form-wizard .nav-wizard li .nav-link.done:after {
    background: var(--primary);
  }
  .form-wizard .nav-wizard li .nav-link.active span,
  .form-wizard .nav-wizard li .nav-link.done span {
    background: var(--primary);
  }
  .check-switch .custom-control-label:after,
  .check-switch .custom-control-label:before {
    border-color: var(--primary);
  }
  .fc-unthemed .fc-today {
    background: $d-bg;
  }
  .fc-unthemed .fc-divider,
  .fc-unthemed .fc-list-heading td,
  .fc-unthemed .fc-popover .fc-header {
    background: $d-border;
  }
  .picker__box {
    background: $d-bg;
    .picker__button--clear,
    .picker__button--close,
    .picker__button--today {
      background: $dark-card;
      color: $white;
      &:hover {
        &:before {
          color: $white;
        }
      }
    }
  }
  .picker {
    color: #999;
  }
  .dtp > .dtp-content {
    background: $d-bg;
  }
  .dtp table.dtp-picker-days tr > td > a {
    color: #68686a;
    &.selected {
      color: $white;
    }
  }
  .order-request tbody tr {
    border-color: $d-border;
  }
  .card-list li {
    color: $white;
  }
  .card-bx .change-btn:hover {
    color: var(--primary);
  }
  .invoice-card {
    &.bg-warning {
      background-color: #5b3c1f !important;
    }
    &.bg-success {
      background-color: #2a6729 !important;
    }
    &.bg-info {
      background-color: #4c276a !important;
    }
    &.bg-secondary {
      background-color: #1c3e52 !important;
    }
  }
  .user-list li {
    border-color: $dark-card;
  }
  .toggle-switch {
    color: $white;
  }
  .bar-chart {
    .apexcharts-text tspan {
      fill: #969ba0;
    }
    line {
      stroke: $d-border;
    }
  }
  .coin-card {
    background: #0f6a62;
    background: -moz-linear-gradient(left, #0f6a62 0%, #084355 100%);
    background: -webkit-linear-gradient(left, #0f6a62 0%, #084355 100%);
    background: linear-gradient(to right, #0f6a62 0%, #084355 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0f6a62', endColorstr='#084355',GradientType=1 );
    .coin-icon {
      background: rgba($black, 0.2);
    }
  }
  .accordion.style-1 .accordion-item,
  .invoice-list {
    border-color: $d-border;
  }
  .accordion.style-1 .accordion-header.collapsed {
    .user-info a,
    .user-info,
    & > span {
      color: $white;
    }
  }
  .ic-card {
    & > a {
      background: #25479f;
      &:first-child {
        border-color: #25479f;
      }
    }
    span {
      color: $white;
    }
  }
  table.dataTable thead th,
  table.dataTable thead td {
    border-color: $d-border !important;
  }
  .form-check .form-check-input {
    background: transparent;
    &:checked {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }
  }
  /* .paging_simple_numbers.dataTables_paginate{
		background:$d-bg;
	} */
  .dataTables_info {
    color: $white;
  }
  .dataTables_wrapper .dataTables_paginate span .paginate_button.current,
  .dataTables_wrapper .dataTables_paginate span .paginate_button:hover {
    background: var(--primary);
    color: $white !important;
  }
  .dashboard-select {
    background: var(--rgba-primary-1);
  }
  .dashboard-select .list {
    background: $dark-card;
  }
  .dashboard-select .option:hover,
  .dashboard-select .option.focus,
  .dashboard-select .option.selected.focus {
    background: $d-bg;
  }
  .card-tabs.style-1 .nav-tabs {
    background: $dark-card;
  }
  .transaction-details {
    border-color: $d-border;
  }
  .description {
    color: $white;
  }
  .transaction-details .amount-bx {
    background: #3f250d;
    i {
      background: #8d3b0c;
    }
  }
  .nice-select .option {
    background: $dark-card;
  }
  .nice-select .option:hover,
  .nice-select .option.focus,
  .nice-select .option.selected.focus {
    background: $dark-card;
  }
  .card-tabs.style-1 {
    border-color: $d-border;
  }

  .nice-select .list {
    background: $d-bg;
  }
  .table-search .input-group .input-group-text {
    background: $dark-card;
  }
  .dark-btn {
    svg {
      path {
        stroke: #fff;
      }
    }
  }
  .bar-chart {
    .apexcharts-legend-text {
      color: $white !important;
    }
  }
  .Sales {
    tspan {
      fill: $white;
    }
  }
  .timeline-chart {
    rect {
      fill: $d-bg;
    }
  }
  .header-info {
    span {
      color: $white !important;
    }
  }
  .smallipop-instance.blue {
    color: #fff;
  }
  .property-map .smallipop.smallimap-mapicon .circle-marker {
    border-color: $d-border;
  }
  .property-features li {
    color: $white;
  }
  .review-tabs-1 {
    background: $dark-card;
  }
  .coin-tabs {
    background: #2e2e40;
    .nav-link {
      &.active {
        background: var(--primary) !important;
        color: $white;
      }
    }
  }
  .separate-row > div {
    border-color: $d-border;
  }
  /* .bg-white{
		background:#2E2E40!important;	
	} */
  .dlabnav .header-info2 span {
    color: $white;
  }
  .brand-title {
    path {
      fill: $white;
    }
    tspan {
      fill: $white;
    }
  }
  .shapreter-row > div {
    border-color: $d-border;
  }
  .chartBar {
    line {
      stroke: $d-border;
    }
  }
  .search-job .search-dropdown:after {
    background: $d-border;
  }
  .chartjs {
    line {
      stroke: $d-border;
    }
  }
  .form-label {
    color: $white;
  }
  .datepicker-switch {
    color: $white;
  }
  .datepicker.datepicker-dropdown td.day,
  .datepicker.datepicker-dropdown th.next,
  .datepicker.datepicker-dropdown th.prev {
    color: $white;
  }
  .datepicker.datepicker-dropdown th.dow {
    color: $white;
  }
  .datepicker.datepicker-dropdown th.datepicker-switch,
  .datepicker.datepicker-dropdown th.next,
  .datepicker.datepicker-dropdown th.prev {
    color: $white;
  }
  .btn-close {
    color: $white !important;
  }
  .bgl-secondary {
    color: $white;
  }
  .application tr td .btn {
    color: $white;
  }
  .btn-link {
    svg {
      path {
        stroke: $white;
      }
    }
  }
  .alert.alert-outline-secondary,
  .alert.alert-outline-dark {
    color: $white;
  }
  .form-control-plaintext {
    color: $white;
  }
  .timeline-panel {
    .media-body {
      small {
        color: $white;
      }
    }
  }
  .jobs {
    border-color: $d-border;
  }
  .search-job .job-title-search .search-area .input-group-text {
    background-color: $d-bg;
  }
  .default-select.style-1 {
    background-color: transparent;
  }
  .copyright strong {
    color: $white;
  }
  .recent-activity:after {
    background-color: $d-border;
  }
  .activity {
    svg {
      path {
        fill: $d-bg;
      }
    }
  }
  .coin-tabs .nav-tabs .nav-item .nav-link {
    color: var(--primary);
    &:hover {
      color: var(--primary) !important;
    }
  }
  .custom-label,
  .custom-label-2,
  .exp-del i,
  .exp-del strong {
    color: $white;
  }
  .listline-wrapper .item:not(:last-child) {
    border-color: $d-border;
  }
  .attachment {
    a {
      color: var(--primary);
      &:hover {
        color: $white;
      }
    }
  }
  .table-bordered > :not(caption) > * {
    border-color: $d-border;
  }
  .read-content-attachment .attachment > div:not(:last-child) {
    border-color: $d-border;
  }
  .bg-img-fix {
    .bg-white {
      background-color: $d-bg !important;
    }
  }
  .datepicker.datepicker-dropdown.dropdown-menu.datepicker-orient-left.datepicker-orient-bottom {
    border-color: $d-border;
  }
  .text-youtube,
  .text-linkedin,
  .text-facebook,
  .text-dribble {
    &:after {
      background-color: $d-border;
    }
  }
  .cm-content-box .cm-content-body {
    background-color: $dark-card;
  }
  .tab-my {
    .nav-link {
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, 0.1);
      color: #fff;
      &.active {
        background-color: var(--primary) !important;
      }
    }
  }
  .dd .accordion-body,
  .dz-terms {
    border-color: $d-border;
  }
  .dlabnav .header-profile2 .sidebar-info i {
    color: $white !important;
  }
  .new-scroll {
    background-color: $d-bg;
  }
  .cm-content-box {
    background-color: $dark-card;
    .content-title {
      border-color: $d-border;
    }
  }
  .cm-content-box.box-primary.style-1 .content-title {
    border-color: $d-border;
  }
}
