#personality-test .accordion-button::after {
  display: none;
}

.personality-accordion .accordion-item {
  border: none;
  margin-bottom: 1.5rem;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.personality-accordion .accordion-header {
  border-radius: 16px !important;
  overflow: hidden;
}

.personality-accordion .accordion-button {
  background: linear-gradient(135deg, #E0F4FF 0%, #B4E4FF 100%);
  border: none;
  padding: 1.5rem 2rem;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  letter-spacing: -0.02em;
  border-radius: 16px;
}

.personality-accordion .accordion-button:not(.collapsed) {
  background: linear-gradient(135deg, #95BDFF 0%, #B4E4FF 100%);
  color: #2B2A4C;
  border-radius: 16px 16px 0 0 !important;
}

.personality-accordion .accordion-button:hover {
  background: linear-gradient(135deg, #9DC7FF 0%, #A5D7FF 100%);
  transform: translateY(-1px);
}

.personality-accordion .accordion-button:focus {
  box-shadow: none;
  border: none;
}

.personality-accordion .accordion-body {
  background: #FFFFFF;
  border-radius: 0 0 16px 16px;
  padding: 2.5rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  font-family: 'Inter', sans-serif;
}

.personality-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  width: 100%;
  padding: 0 15px;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.personality-test-card {
  width: 100%;
  min-width: 0;
  border-radius: 14px;
  padding: 1.8rem;
  background: linear-gradient(to right, #FAFAFA, #FFFFFF);
  border-left: 5px solid;
  position: relative;
  overflow: hidden;
  height: 100%;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.personality-test-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, rgba(255,255,255,0.1), rgba(255,255,255,0.05));
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.personality-test-card.disc {
  border-left-color: #FFB4B4;
}

.personality-test-card.motivators {
  border-left-color: #BCCEF8;
}

.personality-test-card.hvp {
  border-left-color: #98EECC;
}

.personality-test-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.08);
}

.personality-test-card:hover::before {
  opacity: 1;
}

.personality-test-card h3 {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 1.35rem;
  letter-spacing: -0.02em;
  margin-bottom: 1rem;
  color: #2B2A4C;
}

.personality-test-card p {
  font-size: 1.05rem;
  line-height: 1.6;
  color: #4A4A68;
  margin-bottom: 0;
  font-weight: 400;
}

.accordion-icon {
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  color: #2B2A4C;
  opacity: 0.8;
}

.accordion-icon.rotated {
  transform: rotate(180deg);
}

.accordion-body {
  animation: slideDown 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.personality-accordion ::selection {
  background: rgba(180, 228, 255, 0.3);
  color: inherit;
}

.page-header-section {
  position: relative;
  border-radius: 1rem;
  margin: 0 0 3rem;
  background: linear-gradient(120deg, 
    #EDF5FF 0%,    /* Refined pastel blue */
    #E0F4FF 35%,   /* Soft sky blue */
    #D5EBFF 65%,   /* Gentle azure */
    #CAE3FF 100%   /* Subtle powder blue */
  );
}

.page-header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    radial-gradient(circle at 15% 50%, rgba(255, 255, 255, 0.4) 0%, transparent 45%),
    radial-gradient(circle at 85% 50%, rgba(255, 255, 255, 0.4) 0%, transparent 45%);
  opacity: 0.7;
}

.page-title-wrapper {
  position: relative;
  padding: 2rem 2rem;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-title {
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  font-size: 2.8rem;
  background: linear-gradient(120deg, 
    #1E293B 0%,    /* Deep blue-gray */
    #334155 100%   /* Lighter blue-gray */
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0;
  position: relative;
  text-align: center;
  letter-spacing: -0.03em;
  line-height: 1.3;
  max-width: 800px;
  z-index: 1;
}

/* Decorative elements */
.page-header-accents {
  position: absolute;
  border-radius: 1rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
}

.page-header-accents::before,
.page-header-accents::after {
  content: '';
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: linear-gradient(120deg, 
    rgba(180, 228, 255, 0.15),
    rgba(149, 189, 255, 0.15)
  );
  filter: blur(40px);
}

.page-header-accents::before {
  left: -100px;
  top: -150px;
}

.page-header-accents::after {
  right: -100px;
  bottom: -150px;
}

/* Optional: Add subtle top border */
.page-header-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
}

/* Optional: Add subtle bottom shadow */
.page-header-section::after {
  content: '';
  position: absolute;
  bottom: -20px;
  left: 10%;
  right: 10%;
  height: 20px;
  background: linear-gradient(180deg,
    rgba(149, 189, 255, 0.08),
    transparent
  );
  border-radius: 50%;
  filter: blur(8px);
}

@media (max-width: 1024px) {
  .page-title {
    font-size: 2.4rem;
  }
  
  .page-title-wrapper {
    padding: 2rem 2rem;
  }
}

@media (max-width: 768px) {
  .page-title {
    font-size: 2rem;
  }
  
  .page-title-wrapper {
    padding: 2rem 1.5rem;
  }
  
  .page-header-accents::before,
  .page-header-accents::after {
    width: 200px;
    height: 200px;
  }
}

.invite-section {
  background: #FFFFFF;
  border-radius: 20px;
  box-shadow: 
    0 8px 30px rgba(149, 189, 255, 0.12),
    0 2px 6px rgba(149, 189, 255, 0.08);
  margin-bottom: 3.5rem;
  overflow: hidden;
  border: 1px solid rgba(149, 189, 255, 0.15);
}

.invite-header {
  background: linear-gradient(120deg, 
    #F8FBFF 0%, 
    rgba(243, 248, 255, 0.95) 100%
  );
  padding: 1.8rem 2.5rem;
  border-bottom: 1px solid rgba(149, 189, 255, 0.12);
  display: flex;
  align-items: center;
  gap: 1rem;
}

.invite-title {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  color: #2B2A4C;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.875rem;
}

.invite-title svg {
  color: #95BDFF;
}

.invite-body {
  padding: 2.5rem;
}

/* Email Input Section */
.form-group {
  margin-bottom: 2.5rem;
}

.form-label {
  display: block;
  font-weight: 600;
  font-size: 1rem;
  color: #2B2A4C;
  margin-bottom: 0.875rem;
  letter-spacing: -0.01em;
}

.react-multi-email {
  border: 2px solid rgba(149, 189, 255, 0.25) !important;
  border-radius: 12px !important;
  padding: 0.75rem !important;
  min-height: 120px !important;
  background: #FAFBFF !important;
  transition: all 0.2s ease-in-out;
  font-family: 'Inter', sans-serif !important;
  display: flex !important;
  flex-wrap: wrap !important;
  align-items: flex-start !important;
  gap: 4px !important;
}

.react-multi-email:focus-within {
  border-color: #95BDFF !important;
  box-shadow: 0 0 0 4px rgba(149, 189, 255, 0.15) !important;
  background: #FAFBFF !important;
}

/* Input field - Fixed positioning */
.react-multi-email > input {
  font-size: 0.95rem !important;
  padding: 6px 8px !important;
  margin: 0 !important;
  color: #2B2A4C !important;
  background: transparent !important;
  font-family: inherit !important;
  border: none !important;
  outline: none !important;
  flex: 1 !important;
  min-width: 180px !important;
  line-height: 1.5 !important;
}

/* Placeholder styling */
.react-multi-email > input::placeholder {
  color: #94A3B8 !important;
  opacity: 0.8 !important;
  line-height: 1.5 !important;
}

.react-multi-email > span[data-placeholder]{
  left: 1.5em !important;
  top: 1.1em !important;
}

/* Email tags */
[data-tag] {
  background: #EDF5FF !important;
  border: 1px solid #B4E4FF !important;
  color: #2B2A4C !important;
  border-radius: 6px !important;
  padding: 4px 10px !important;
  font-size: 0.875rem !important;
  display: inline-flex !important;
  align-items: center !important;
  gap: 6px !important;
  margin: 2px !important;
  line-height: 1.5 !important;
}

/* Remove button in tags */
[data-tag-handle] {
  color: #64748B !important;
  padding-left: 4px !important;
  cursor: pointer !important;
  font-size: 1.1rem !important;
  font-weight: 500 !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  transition: color 0.2s ease !important;
}

[data-tag-handle]:hover {
  color: #EF4444 !important;
}

/* Helper text */
.helper-text {
  font-size: 0.875rem;
  color: #64748B;
  margin-top: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.helper-text svg {
  color: #95BDFF;
}

/* Test Selection Section */
.test-selection {
  background: linear-gradient(150deg, 
    #F8FBFF 0%, 
    rgba(243, 248, 255, 0.5) 100%
  );
  border-radius: 16px;
  padding: 2rem;
  border: 1px solid rgba(149, 189, 255, 0.15);
}

.test-selection-header {
  margin-bottom: 1.5rem;
  text-align: left;
}

.test-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 1.25rem;
}

.test-option {
  background: #FFFFFF;
  border: 2px solid rgba(149, 189, 255, 0.2);
  border-radius: 12px;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  user-select: none;
}

.test-option:hover {
  border-color: #95BDFF;
  background: #FAFBFF;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(149, 189, 255, 0.12);
}

/* Add styles for selected state */
.test-option.selected {
  border-color: #95BDFF;
  background: #F8FBFF;
  box-shadow: 0 4px 12px rgba(149, 189, 255, 0.12);
}

/* Custom Checkbox Design */
.test-checkbox-wrapper {
  position: relative;
  width: 22px;
  height: 22px;
}

.test-checkbox {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.checkbox-custom {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  border: 2px solid #95BDFF;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.test-checkbox:checked + .checkbox-custom {
  background: #95BDFF;
}

.test-checkbox:checked + .checkbox-custom::after {
  content: '✓';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 14px;
}

.test-label {
  font-weight: 500;
  color: #2B2A4C;
  font-size: 1rem;
  margin: 0;
  flex: 1;
}

/* Action Button */
.invite-actions {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(149, 189, 255, 0.15);
}

.btn-invite {
  background: linear-gradient(120deg, #95BDFF 0%, #7BA4FF 100%);
  color: #FFFFFF;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem 2.5rem;
  border-radius: 12px;
  border: none;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

/* Active state */
.btn-invite:enabled {
  cursor: pointer;
}

.btn-invite:enabled:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(149, 189, 255, 0.3);
}

.btn-invite:enabled:active {
  transform: translateY(0);
}

/* Disabled state */
.btn-invite:disabled {
  background: linear-gradient(120deg, #E2E8F0 0%, #CBD5E1 100%);
  color: #94A3B8;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

@media (max-width: 768px) {
  .invite-header {
    padding: 1.5rem 1.25rem;
  }

  .invite-body {
    padding: 1.5rem;
  }

  .test-selection {
    padding: 1.5rem;
  }

  .test-options {
    grid-template-columns: 1fr;
  }
}

/* Invited Users Section Styles */
.invited-users-section {
  background: #FFFFFF;
  border-radius: 24px;
  box-shadow: 
    0 12px 40px rgba(149, 189, 255, 0.12),
    0 4px 12px rgba(149, 189, 255, 0.05);
  margin-bottom: 4rem;
  overflow: hidden;
  border: 1px solid rgba(149, 189, 255, 0.15);
}

.invited-users-header {
  background: linear-gradient(135deg, 
    #F8FBFF 0%, 
    rgba(243, 248, 255, 0.98) 100%
  );
  padding: 2rem 2.5rem;
  border-bottom: 1px solid rgba(149, 189, 255, 0.12);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invited-users-title {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  color: #2B2A4C;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.875rem;
}

.invited-users-title svg {
  color: #95BDFF;
}

.invited-users-body {
  padding: 2rem 2.5rem;
  max-width: 100%;
  overflow-x: hidden;
}

/* Table Styles */
.table-container {
  width: 100%;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  background: linear-gradient(to right, #F8FBFF, #FFFFFF);
  border-radius: 16px;
  border: 1px solid rgba(149, 189, 255, 0.15);
  position: relative;
}

.users-table {
  min-width: 800px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
}

.users-table th {
  background: rgba(149, 189, 255, 0.08);
  padding: 1.25rem 1.5rem;
  text-align: left;
  font-weight: 600;
  color: #2B2A4C;
  font-size: 0.95rem;
  letter-spacing: 0.01em;
  border-bottom: 2px solid rgba(149, 189, 255, 0.15);
  white-space: nowrap;
  transition: background-color 0.2s ease;
}

.users-table td {
  padding: 1.25rem 1.5rem;
  color: #4A4A68;
  font-size: 0.95rem;
  border-bottom: 1px solid rgba(149, 189, 255, 0.1);
  background: #FFFFFF;
  transition: background-color 0.2s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 0;
}

.users-table tr:hover td {
  background: #F8FBFF;
}

/* Status Badge Styles */
.status-badge {
  padding: 0.625rem 1.25rem;
  border-radius: 100px;
  font-size: 0.875rem;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
  transition: all 0.2s ease;
}

.status-badge::before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
}

.status-badge.completed {
  background: rgba(34, 197, 94, 0.1);
  color: #16A34A;
}

.status-badge.completed::before {
  background: #16A34A;
}

.status-badge.in-progress {
  background: rgba(59, 130, 246, 0.1);
  color: #2563EB;
}

.status-badge.in-progress::before {
  background: #2563EB;
}

.status-badge.not-started {
  background: rgba(245, 158, 11, 0.1);
  color: #D97706;
}

.status-badge.not-started::before {
  background: #D97706;
}

/* Action Buttons */
.action-btn {
  font-size: 0.875rem;
  padding: 0.75rem 1.25rem;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.625rem;
  transition: all 0.2s ease;
  font-weight: 600;
}

.view-report-btn {
  color: #2563EB;
  background: rgba(59, 130, 246, 0.1);
}

.view-report-btn:hover {
  background: rgba(59, 130, 246, 0.15);
  transform: translateY(-1px);
}

.resend-invite-btn {
  color: #7BA4FF;
  background: rgba(149, 189, 255, 0.1);
}

.resend-invite-btn:hover {
  background: rgba(149, 189, 255, 0.15);
  transform: translateY(-1px);
}

/* Pagination Styles */
.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0 0;
  margin-top: 2rem;
  border-top: 1px solid rgba(149, 189, 255, 0.15);
}

.pagination-info {
  color: #64748B;
  font-size: 0.875rem;
  font-weight: 500;
}

.pagination-buttons {
  display: flex;
  gap: 0.75rem;
}

.pagination-btn {
  padding: 0.75rem 1.5rem;
  border-radius: 10px;
  border: 1px solid rgba(149, 189, 255, 0.3);
  background: #FFFFFF;
  color: #2B2A4C;
  font-weight: 600;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.pagination-btn:not(:disabled):hover {
  background: #F8FBFF;
  border-color: #95BDFF;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(149, 189, 255, 0.1);
}

.pagination-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .invited-users-header,
  .invited-users-body {
    padding: 1.5rem;
  }
  
  .pagination-container {
    flex-direction: column;
    gap: 15px;
    align-items: center;
    text-align: center;
  }
  
  .pagination-buttons {
    width: 100%;
  }
  
  .pagination-btn {
    flex: 1;
    justify-content: center;
  }

  .pagination-info {
    width: 100%;
    text-align: center;
  }

  .invited-users-body {
    padding: 1rem;
    overflow-x: hidden;
  }

  .table-container {
    margin: 0;
    padding: 0;
    border-radius: 0;
    border-left: none;
    border-right: none;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .table-container::-webkit-scrollbar {
    display: none;
  }

  .users-table {
    margin: 0;
    border-radius: 0;
  }
}

/* Empty State Styles */
.empty-state {
  padding: 4rem 2rem;
  text-align: center;
  background: linear-gradient(to bottom, #F8FBFF, #FFFFFF);
  border-radius: 16px;
  border: 1px solid rgba(149, 189, 255, 0.15);
}

.empty-state-icon {
  width: 64px;
  height: 64px;
  background: rgba(149, 189, 255, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.5rem;
  color: #95BDFF;
}

.empty-state-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2B2A4C;
  margin-bottom: 0.75rem;
}

.empty-state-description {
  color: #64748B;
  font-size: 0.95rem;
  max-width: 400px;
  margin: 0 auto;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .personality-cards-grid {
    grid-template-columns: 1fr;
    padding: 0 10px;
  }

  .personality-test-card {
    max-width: 100%;
    margin: 0 auto;
  }

  .invited-users-body {
    padding: 1rem;
  }

  .table-container {
    margin: 0;
    padding: 0;
    border-radius: 0;
    border-left: none;
    border-right: none;
  }

  .users-table {
    margin: 0;
    border-radius: 0;
  }
}

/* Table cell text ellipsis */
.users-table td {
  padding: 1.25rem 1.5rem;
  color: #4A4A68;
  font-size: 0.95rem;
  border-bottom: 1px solid rgba(149, 189, 255, 0.1);
  background: #FFFFFF;
  transition: background-color 0.2s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 0;
}

/* Specific column widths for better control */
.users-table th,
.users-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* You can adjust these widths based on your needs */
.users-table th:nth-child(1),
.users-table td:nth-child(1) {
  width: 25%; /* Name column */
}

.users-table th:nth-child(2),
.users-table td:nth-child(2) {
  width: 25%; /* Email column */
}

.users-table th:nth-child(3),
.users-table td:nth-child(3) {
  width: 20%; /* Status column */
}

.users-table th:nth-child(4),
.users-table td:nth-child(4) {
  width: 30%; /* Actions column */
}
