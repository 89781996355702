// Variable.scss

.nestable-cart {
  overflow: hidden;
}

// .dd-item > button {
//     height: 2.1rem;
// }
.dd-handle {
  color: $white;
  background: var(--primary);
  border-radius: 0.3125rem;
  padding: 0.5rem 1rem;
  height: auto;
  border: 1px solid $border;
  cursor: all-scroll;
}
.dd-handle:hover {
  color: $white;
  background: var(--primary);
}
// #nestable2 .dd-handle {
// color: $white;
// border: 1px solid $border;
// background: $primary;
// }
// #nestable2 .dd-handle:hover {
//     background: $primary;
// }
// #nestable2 .dd-item>button:before {
//     color: $white;
// }
.dd3-content:hover {
  color: $white;
  background: var(--primary);
}
.dd3-content {
  color: $white;
}
.dd-item > button {
  line-height: 28px;
  color: $white;
}
