/* Header Section Styles */
.job-application-header {
  background: linear-gradient(135deg, #f6f8ff 0%, #f0f4ff 100%);
  margin-bottom: 2.5rem;
  padding: 2rem 2.5rem;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: visible;
}

/* Container for title and buttons */
.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 2;
}

.header-title {
  font-size: 1.875rem;
  font-weight: 700;
  color: #1f2937;
  margin-bottom: 0px !important;
  margin: 0;
}

.header-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 0;
  position: relative;
  z-index: 2;
}

/* Button Styles */
.btn-primary, 
.btn-outline-primary {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.btn-primary {
  background: linear-gradient(135deg, #818cf8 0%, #6366f1 100%);
  border: none;
  color: white;
}

.btn-primary:hover {
  background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(99, 102, 241, 0.2);
}

.btn-outline-primary {
  background: white;
  border: 2px solid #818cf8;
  color: #6366f1;
}

.btn-outline-primary:hover {
  background: #f5f6ff;
  border-color: #6366f1;
  color: #4f46e5;
  transform: translateY(-1px);
}

/* Disabled state */
.btn-primary:disabled,
.btn-outline-primary:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

/* Spinner styles */
.spinner-border {
  width: 1rem !important;
  height: 1rem !important;
  border-width: 0.15em;
}

/* Responsive Design */
@media (max-width: 768px) {
  .job-application-header {
    padding: 1.5rem;
  }

  .header-content {
    flex-direction: column;
    gap: 1rem;
  }

  .header-title {
    font-size: 1.5rem;
    text-align: center;
  }

  .header-actions {
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.75rem;
  }

  .btn-primary, 
  .btn-outline-primary {
    padding: 0.625rem 1.25rem;
    font-size: 0.875rem;
  }
}

/* Icon Animations */
.btn-primary svg,
.btn-outline-primary svg {
  transition: transform 0.3s ease;
}

.btn-primary:hover svg,
.btn-outline-primary:hover svg {
  transform: scale(1.1);
}

/* Background Animation */
@keyframes headerShine {
  0% {
    transform: translateX(-100%) rotate(45deg);
  }
  100% {
    transform: translateX(100%) rotate(45deg);
  }
}

/* Enhance the profile section styling */
.profile-container {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
  transition: all 0.3s ease;
}

.profile-container:hover {
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.08);
}

/* Style for the name and details */
h3 {
  font-size: 1.875rem !important;
  font-weight: 700 !important;
  color: #1f2937 !important;
  margin-bottom: 1rem !important;
  background: linear-gradient(135deg, #1f2937 0%, #4b5563 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Style for contact info items */
div[style*="display: flex"][style*="alignItems: center"] {
  padding: 0.5rem 0;
  color: #4b5563;
  font-size: 1.125rem;
  transition: all 0.3s ease;
}

div[style*="display: flex"][style*="alignItems: center"]:hover {
  color: #6366f1;
}

/* Style for icons */
div[style*="display: flex"][style*="alignItems: center"] svg {
  color: #6366f1;
  margin-right: 0.75rem;
  transition: transform 0.3s ease;
}

div[style*="display: flex"][style*="alignItems: center"]:hover svg {
  transform: scale(1.1);
}

/* Style for the match percentage indicator */
.percentage-indicator {
  position: relative;
  color: white;
  font-weight: 800;
  font-size: 2rem;
  transition: all 0.3s ease;
  animation: float 3s ease-in-out infinite;
  backdrop-filter: blur(5px);
  border: 3px solid rgba(255, 255, 255, 0.3);
  z-index: 1;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.percentage-indicator::before {
  content: '';
  position: absolute;
  inset: -2px;
  border-radius: 50%;
  padding: 2px;
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  animation: rotate 4s linear infinite;
}

.percentage-indicator::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent 40%,
    rgba(255, 255, 255, 0.2) 50%,
    transparent 60%
  );
  transform: rotate(45deg);
  animation: shine 3s infinite;
}

.percentage-indicator.high {
  background: linear-gradient(135deg, #22c55e 0%, #16a34a 100%);
  box-shadow: 0 8px 32px rgba(34, 197, 94, 0.3);
}

.percentage-indicator.high::before {
  background: linear-gradient(135deg, #22c55e, #16a34a, #22c55e);
}

.percentage-indicator.medium {
  background: linear-gradient(135deg, #f59e0b 0%, #d97706 100%);
  box-shadow: 0 8px 32px rgba(245, 158, 11, 0.3);
}

.percentage-indicator.medium::before {
  background: linear-gradient(135deg, #f59e0b, #d97706, #f59e0b);
}

.percentage-indicator.low {
  background: linear-gradient(135deg, #ef4444 0%, #dc2626 100%);
  box-shadow: 0 8px 32px rgba(239, 68, 68, 0.3);
}

.percentage-indicator.low::before {
  background: linear-gradient(135deg, #ef4444, #dc2626, #ef4444);
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes shine {
  0% { transform: translateX(-100%) rotate(45deg); }
  80%, 100% { transform: translateX(100%) rotate(45deg); }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .profile-container {
    padding: 1.5rem;
  }

  h3 {
    font-size: 1.5rem !important;
    text-align: center;
  }

  div[style*="display: flex"][style*="alignItems: center"] {
    justify-content: center;
    font-size: 1rem;
  }
}

/* Add this to ensure the animation works */
div[style*="animation: float"] {
  animation: float 3s ease-in-out infinite;
}

@keyframes gentleFloat {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* Add this to ensure the animation works */
div[style*="animation: gentleFloat"] {
  animation: gentleFloat 4s ease-in-out infinite;
}

/* Contact Info Section Styles */
div[style*="display: flex"][style*="alignItems: center"][style*="gap: '.5rem'"] {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  background: #f8fafc;
  border-radius: 8px;
  transition: all 0.2s ease;
  border: 1px solid #e2e8f0;
}

div[style*="display: flex"][style*="alignItems: center"][style*="gap: '.5rem'"]:hover {
  background: #f1f5f9;
  transform: translateX(5px);
  border-color: #cbd5e1;
}

/* Icon styles */
div[style*="display: flex"][style*="alignItems: center"][style*="gap: '.5rem'"] svg {
  color: #6366f1;
  min-width: 18px;
  min-height: 18px;
  transition: all 0.2s ease;
}

div[style*="display: flex"][style*="alignItems: center"][style*="gap: '.5rem'"]:hover svg {
  color: #4f46e5;
  transform: scale(1.1);
}

/* Text styles */
div[style*="display: flex"][style*="alignItems: center"][style*="gap: '.5rem'"] {
  color: #475569;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.5;
}

/* Container styles */
div[style*="width"][style*="70%"] {
  background: white;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  div[style*="display: flex"][style*="alignItems: center"][style*="gap: '.5rem'"] {
    padding: 0.625rem 0.875rem;
    margin-bottom: 0.375rem;
    font-size: 0.875rem;
  }

  div[style*="display: flex"][style*="alignItems: center"][style*="gap: '.5rem'"]:hover {
    transform: translateX(0);
    background: #f1f5f9;
  }

  div[style*="width"][style*="70%"] {
    padding: 1rem;
  }

  /* Improve spacing for mobile */
  div[style*="display: flex"][style*="flexDirection: column"] > div {
    margin-bottom: 0.5rem;
  }
}

/* Add subtle dividers between sections */
div[style*="display: flex"][style*="alignItems: center"][style*="gap: '.5rem'"]:not(:last-child) {
  border-bottom: 1px solid #f1f5f9;
}

/* Enhance text overflow handling */
div[style*="display: flex"][style*="alignItems: center"][style*="gap: '.5rem'"] span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Add hover effect for better interactivity */
div[style*="display: flex"][style*="alignItems: center"][style*="gap: '.5rem'"]:hover span {
  color: #1f2937;
}

/* Add subtle animation for loading */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

div[style*="display: flex"][style*="alignItems: center"][style*="gap: '.5rem'"] {
  animation: fadeIn 0.3s ease-out forwards;
  animation-delay: calc(var(--index, 0) * 0.1s);
}

/* Description Section Styles */
.description-section {
  background: linear-gradient(135deg, #ffffff 0%, #f8faff 100%);
  border-radius: 1.5rem;
  padding: 3rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  margin: 2rem 0;
}

/* Description Heading */
.description-section h3 {
  font-size: 2.25rem;
  font-weight: 700;
  color: #1e293b;
  margin-bottom: 2rem;
  position: relative;
  display: inline-block;
}

.description-section h3::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 60px;
  height: 4px;
  background: linear-gradient(90deg, #818cf8 0%, #6366f1 100%);
  border-radius: 2px;
}

/* Description Content */
.description-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Description Row */
.description-row {
  display: flex;
  align-items: center;
  padding: 1.25rem;
  border-bottom: 1px solid #e5e7eb;
  transition: all 0.3s ease;
  background: white;
  border-radius: 0.75rem;
}

.description-row:hover {
  transform: translateX(10px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

/* Label */
.description-label {
  flex: 0 0 40%;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #64748b;
  font-size: 0.95rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

.description-label svg {
  width: 1.25rem;
  height: 1.25rem;
  color: #6366f1;
}

/* Value */
.description-value {
  flex: 0 0 60%;
  color: #1e293b;
  font-size: 1rem;
  font-weight: 500;
}

/* Skills Container */
.skills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

/* Skill Tag */
.skill-tag {
  background: #f1f5f9;
  color: #4f46e5;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.skill-tag:hover {
  background: #e0e7ff;
  transform: translateY(-2px);
}

/* Status Badge */
.status-badge {
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 1rem;
  font-weight: 500;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: capitalize;
}

.status-badge.active {
  background: #dcfce7;
  color: #15803d;
}

.status-badge.pending {
  background: #fef3c7;
  color: #b45309;
}

.status-badge.rejected {
  background: #fee2e2;
  color: #b91c1c;
}

/* Responsive Design */
@media (max-width: 768px) {
  .description-section {
    padding: 1.5rem;
  }

  .description-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .description-label,
  .description-value {
    flex: 0 0 100%;
  }

  .description-label {
    font-size: 0.875rem;
  }

  .status-badge {
    font-size: 0.875rem;
  }
}

/* Loading State Animation */
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.skeleton-loading {
  background: linear-gradient(90deg, #f3f4f6 25%, #e5e7eb 50%, #f3f4f6 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 0.5rem;
}

/* Font Loading Optimization */
@font-face {
  font-family: 'Inter';
  font-display: swap;
  src: url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
}

/* Work Experience Section */
.work-experience-section {
  background: linear-gradient(135deg, #ffffff 0%, #f8faff 100%);
  border-radius: 1.5rem;
  padding: 3rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  margin: 2rem 0;
}

/* Experience Card */
.experience-card {
  background: white;
  border-radius: 1rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border: 1px solid #e5e7eb;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  animation: slideIn 0.5s ease-out forwards;
  animation-delay: calc(var(--delay) * 0.1s);
  opacity: 1 !important;
}

.experience-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: linear-gradient(to bottom, #818cf8, #6366f1);
  border-radius: 4px 0 0 4px;
}

.experience-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.06);
  border-color: #c7d2fe;
}

/* Role */
.experience-role {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.experience-role svg {
  color: #6366f1;
  width: 1rem;
  height: 1rem;
}

/* Company */
.experience-company {
  font-size: 1rem;
  color: #64748b;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.experience-company svg {
  color: #818cf8;
}

/* Duration */
.experience-duration {
  font-size: 0.813rem;
  color: #6b7280;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.experience-duration svg {
  color: #818cf8;
}

/* Responsibilities Section */
.responsibilities-section {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #e5e7eb;
}

.responsibilities-title {
  font-size: 1rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.responsibilities-title svg {
  color: #6366f1;
}

.responsibilities-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.responsibility-item {
  position: relative;
  padding-left: 1.25rem;
  margin-bottom: 0.5rem;
  color: #4b5563;
  line-height: 1.5;
  font-size: 0.875rem;
}

.responsibility-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0.6rem;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #818cf8;
}

/* Animations */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .work-experience-section {
    padding: 1.5rem;
  }

  .experience-card {
    padding: 1.5rem;
  }

  .experience-role {
    font-size: 1.125rem;
  }

  .experience-company {
    font-size: 0.938rem;
  }

  .responsibilities-title {
    font-size: 0.938rem;
  }

  .responsibility-item {
    font-size: 0.813rem;
  }
}

/* Candidate Analysis Section Styles */
.candidate-analysis-section {
  background: linear-gradient(135deg, #ffffff 0%, #f8faff 100%);
  border-radius: 1.5rem;
  padding: 3rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  margin: 2.5rem 0;
}

.analysis-main-heading {
  font-size: 2rem;
  font-weight: 700;
  color: #1e293b;
  margin-bottom: 2.5rem !important;
  position: relative;
  display: inline-block;
  padding-bottom: 0.75rem;
}

.analysis-main-heading::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80px;
  height: 4px;
  background: linear-gradient(90deg, #818cf8 0%, #6366f1 100%);
  border-radius: 2px;
}

.analysis-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.analysis-card {
  background: white;
  border-radius: 1.25rem;
  padding: 2rem;
  border: 1px solid #e5e7eb;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.analysis-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.06);
}

.pros-section {
  border-left: 6px solid #22c55e;
  background: linear-gradient(to right, #f0fdf4 0%, #ffffff 100%);
}

.cons-section {
  border-left: 6px solid #ef4444;
  background: linear-gradient(to right, #fef2f2 0%, #ffffff 100%);
}

.analysis-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

.header-icon {
  padding: 0.75rem;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pros-icon {
  background-color: #dcfce7;
  color: #16a34a;
}

.cons-icon {
  background-color: #fee2e2;
  color: #dc2626;
}

.analysis-header h4 {
  font-size: 1.375rem;
  font-weight: 600;
  margin: 0;
  color: #1e293b;
}

.analysis-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.analysis-item {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 0.75rem 0;
  color: #1f2937;
  font-size: 1rem;
  line-height: 1.6;
}

.bullet-point {
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 600;
}

.pros-item .bullet-point {
  background-color: #dcfce7;
  color: #16a34a;
}

.cons-item .bullet-point {
  background-color: #fee2e2;
  color: #dc2626;
}

.no-analysis {
  text-align: center;
  color: #6b7280;
  font-size: 1.125rem;
  padding: 2rem;
  background: #f9fafb;
  border-radius: 1rem;
  border: 1px dashed #e5e7eb;
}

/* Responsive Design */
@media (max-width: 768px) {
  .candidate-analysis-section {
    padding: 1.5rem;
    margin: 1.5rem 0;
  }

  .analysis-main-heading {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 2rem !important;
  }

  .analysis-main-heading::after {
    left: 50%;
    transform: translateX(-50%);
  }

  .analysis-card {
    padding: 1.25rem;
  }

  .analysis-header h4 {
    font-size: 1.125rem;
  }

  .analysis-item {
    font-size: 0.875rem;
  }
}

/* Education Section Styles */
.education-section {
  background: linear-gradient(135deg, #ffffff 0%, #f8faff 100%);
  border-radius: 1.5rem;
  padding: 3rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  margin: 2.5rem 0;
}

.section-heading {
  font-size: 2rem;
  font-weight: 700;
  color: #1e293b;
  margin-bottom: 2.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  padding-bottom: 0.75rem;
}

.section-heading::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80px;
  height: 4px;
  background: linear-gradient(90deg, #818cf8 0%, #6366f1 100%);
  border-radius: 2px;
}

.section-icon {
  color: #6366f1;
  font-size: 2rem;
  margin: 0 1rem 0 -1rem;
}

.education-timeline {
  position: relative;
  padding-left: 2rem;
}

.education-timeline::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 2px;
  background: linear-gradient(to bottom, #e0e7ff 0%, #c7d2fe 100%);
  border-radius: 1px;
}

.education-card {
  background: white;
  border-radius: 1.25rem;
  padding: 2rem;
  margin-bottom: 2rem;
  border: 1px solid #e5e7eb;
  position: relative;
  transition: all 0.3s ease;
  animation: slideIn 0.5s ease-out forwards;
  animation-delay: calc(var(--delay) * 0.1s);
  opacity: 1 !important;
}

.education-card::before {
  content: '';
  position: absolute;
  left: -2.5rem;
  top: 2rem;
  width: 1rem;
  height: 1rem;
  background: #6366f1;
  border: 3px solid #e0e7ff;
  border-radius: 50%;
  z-index: 1;
}

.education-card:hover {
  transform: translateX(10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.06);
  border-color: #c7d2fe;
}

.education-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  gap: 1rem;
}

.degree-info {
  flex: 1;
}

.degree-name {
  font-size: 1.375rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 0.5rem;
}

.institution-name {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #64748b;
  font-size: 1.125rem;
  font-weight: 500;
}

.education-meta {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-end;
}

.education-year {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: #f1f5f9;
  border-radius: 2rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: #475569;
  white-space: nowrap;
  transition: all 0.3s ease;
  border: 1px solid #e2e8f0;
}

.education-year:hover {
  background: #e2e8f0;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.education-year .icon {
  color: #6366f1;
  font-size: 1rem;
}

/* Add responsive handling for dates */
@media (max-width: 768px) {
  .education-meta {
    align-items: flex-start;
    margin-top: 0.5rem;
  }

  .education-year {
    font-size: 0.813rem;
    padding: 0.375rem 0.75rem;
  }
}

.specialization-info {
  margin-bottom: 1.5rem;
  padding: 1rem;
  background: #f8fafc;
  border-radius: 0.75rem;
}

.specialization-info .label {
  color: #64748b;
  font-weight: 500;
  margin-right: 0.5rem;
}

.specialization-info .value {
  color: #1e293b;
  font-weight: 500;
}

.achievements-section {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid #e5e7eb;
}

.achievements-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 1rem;
}

.achievements-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.achievement-item {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.75rem;
  color: #4b5563;
  font-size: 0.938rem;
  line-height: 1.5;
}

.achievement-item::before {
  content: '•';
  position: absolute;
  left: 0.5rem;
  color: #6366f1;
  font-weight: bold;
}

/* Profile Summary Section */
.profile-summary-section {
  background: linear-gradient(135deg, #ffffff 0%, #f8faff 100%);
  border-radius: 1.5rem;
  padding: 2.5rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  margin: 2.5rem 0;
}

.summary-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  align-items: stretch;
}

/* Summary Cards */
.summary-card {
  background: white;
  border-radius: 1rem;
  padding: 1.5rem;
  border: 1px solid #e5e7eb;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.summary-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.06);
  border-color: #c7d2fe;
}

/* Card Icons */
.card-icon {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f1f5f9;
  color: #6366f1;
  font-size: 1.5rem;
  transition: all 0.3s ease;
}

.summary-card:hover .card-icon {
  background: #6366f1;
  color: white;
  transform: scale(1.1);
}

/* Card Content */
.card-content {
  flex: 1;
}

.card-content h4 {
  font-size: 0.875rem;
  font-weight: 600;
  color: #64748b;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.card-content p {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

.resume-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  width: 100%;
  padding: 0.75rem 1.5rem;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.75rem;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.resume-button:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
  transform: translateY(-2px);
}

.button-icon {
  font-size: 1.25rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .profile-summary-section {
    padding: 1.5rem;
    margin: 1.5rem 0;
  }

  .summary-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .summary-card {
    padding: 1.25rem;
  }

  .card-icon {
    width: 40px;
    height: 40px;
    font-size: 1.25rem;
  }

  .card-content h4 {
    font-size: 0.813rem;
  }

  .card-content p {
    font-size: 1rem;
  }

  .resume-button {
    padding: 0.625rem 1.25rem;
    font-size: 0.938rem;
  }
}

/* Animation */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(99, 102, 241, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(99, 102, 241, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(99, 102, 241, 0);
  }
}

/* .resume-card {
  animation: pulse 2s infinite;
} */

.profile-stats-grid {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  background: #ffffff;
  border-radius: 1rem;
  border: 1px solid rgba(226, 232, 240, 0.6);
}

/* Stats Row */
.stats-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

/* Stat Card */
.stat-card {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.25rem;
  background: #f8fafc;
  border-radius: 0.75rem;
  border: 1px solid #e2e8f0;
  transition: all 0.2s ease;
}

.stat-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  background: white;
  border-radius: 10px;
  color: #64748b;
  font-size: 1.25rem;
  border: 1px solid #e2e8f0;
}

.stat-content {
  flex: 1;
}

.stat-label {
  font-size: 0.75rem;
  font-weight: 600;
  color: #64748b;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  margin-bottom: 0.25rem;
}

.stat-value {
  font-size: 1rem;
  font-weight: 600;
  color: #1e293b;
  padding: 0.5rem 0 !important;
}

/* Resume Button Container */
.resume-card {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

/* Resume Button */
.view-resume-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 0.875rem 2rem;
  background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
  border: none;
  border-radius: 0.75rem;
  color: white;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 4px 6px -1px rgba(99, 102, 241, 0.1), 0 2px 4px -1px rgba(99, 102, 241, 0.06);
  position: relative;
  overflow: hidden;
}

.view-resume-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.view-resume-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 15px -3px rgba(99, 102, 241, 0.2), 0 4px 6px -2px rgba(99, 102, 241, 0.1);
}

.view-resume-btn:hover::before {
  left: 100%;
}

.btn-icon {
  font-size: 1.125rem;
  transition: transform 0.2s ease;
}

.view-resume-btn:hover .btn-icon {
  transform: translateX(2px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .stats-row {
    grid-template-columns: 1fr;
  }
  
  .stat-card {
    padding: 1rem;
  }

  .view-resume-btn {
    width: 100%;
    padding: 0.75rem 1.5rem;
  }
}

/* PDF Export Styles - Add these to your existing CSS */

/* 1. Header Section */
.pdf-export .job-application-header {
  background: #f6f8ff;
  box-shadow: none;
}

/* 2. Primary Button */
.pdf-export .btn-primary {
  background: #6366f1;
  box-shadow: none;
}

.pdf-export .btn-primary:hover {
  background: #4f46e5;
  transform: none;
}

/* 3. Headings */
.pdf-export h3 {
  background: none;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: #1f2937;
  color: #1f2937;
}

/* 4. Description Section */
.pdf-export .description-section {
  background: #ffffff;
  box-shadow: none;
}

/* 5. Work Experience Section */
.pdf-export .work-experience-section {
  background: #ffffff;
}

.pdf-export .experience-card::before {
  background: #6366f1;
}

/* 6. Education Section */
.pdf-export .education-section {
  background: #ffffff;
}

.pdf-export .education-timeline::before {
  background: #c7d2fe;
}

.pdf-export .section-heading::after {
  background: #6366f1;
}

/* 7. Profile Summary Section */
.pdf-export .profile-summary-section {
  background: #ffffff;
  box-shadow: none;
}

/* 8. View Resume Button */
.pdf-export .view-resume-btn {
  background: #6366f1;
  box-shadow: none;
}

/* 9. Candidate Analysis Section */
.pdf-export .candidate-analysis-section {
  background: #ffffff;
}

.pdf-export .analysis-main-heading::after {
  background: #6366f1;
}

.pdf-export .pros-section {
  background: #f0fdf4;
}

.pdf-export .cons-section {
  background: #fef2f2;
}

/* Disable animations and transitions in PDF export */
.pdf-export * {
  animation: none !important;
  transition: none !important;
  transform: none !important;
  box-shadow: none !important;
}

/* PDF Export Heading Styles */
.pdf-export h3,
.pdf-export .section-heading,
.pdf-export .description-section h3,
.pdf-export .work-experience-section h3,
.pdf-export .education-section h3,
.pdf-export .analysis-main-heading,
.pdf-export .assessment-heading {
  background: none !important;
  -webkit-background-clip: unset !important;
  -webkit-text-fill-color: #1f2937 !important;
  color: #1f2937 !important;
  text-shadow: none !important;
}

/* Experience Cards in PDF */
.pdf-export .experience-card {
  background: #ffffff !important;
  border: 1px solid #e5e7eb !important;
  color: #1f2937 !important;
}

.pdf-export .experience-card .experience-role,
.pdf-export .experience-card .experience-company,
.pdf-export .experience-card .experience-duration,
.pdf-export .experience-card .responsibilities-title {
  color: #1f2937 !important;
}

/* Education Cards in PDF */
.pdf-export .education-card {
  background: #ffffff !important;
  border: 1px solid #e5e7eb !important;
}

.pdf-export .education-card .degree-name,
.pdf-export .education-card .institution-name,
.pdf-export .education-card .education-year,
.pdf-export .education-card .education-grade {
  color: #1f2937 !important;
}

/* Assessment Report in PDF */
.pdf-export .analysis-card {
  background: #ffffff !important;
  border: 1px solid #e5e7eb !important;
}

.pdf-export .analysis-card h4,
.pdf-export .analysis-card .analysis-item {
  color: #1f2937 !important;
}

/* Description Section in PDF */
.pdf-export .description-section {
  background: #ffffff !important;
}

.pdf-export .description-label,
.pdf-export .description-value {
  color: #1f2937 !important;
}

/* Job Details Section in PDF */
.pdf-export .job-details-section {
  background: #ffffff !important;
}

.pdf-export .job-details-section h3,
.pdf-export .job-details-content {
  color: #1f2937 !important;
}

/* Common text elements in PDF */
.pdf-export p,
.pdf-export span,
.pdf-export li {
  color: #1f2937 !important;
}

/* Ensure icons are visible */
.pdf-export svg {
  color: #6366f1 !important;
}

/* Remove any dark overlays or backgrounds */
.pdf-export [class*="overlay"],
.pdf-export [class*="background"] {
  background: transparent !important;
}

/* Add borders for better section separation */
.pdf-export .section {
  border: 1px solid #e5e7eb !important;
  margin-bottom: 1rem !important;
}

/* Target all headings with gradient backgrounds in PDF export */
.pdf-export h1,
.pdf-export h2,
.pdf-export h3,
.pdf-export h4,
.pdf-export h5,
.pdf-export h6,
.pdf-export .section-title,
.pdf-export .description-section h3,
.pdf-export .work-experience-section h3,
.pdf-export .education-section h3,
.pdf-export .analysis-main-heading,
.pdf-export .assessment-heading,
.pdf-export div[style*="background: linear-gradient(135deg, #1f2937 0%, #4b5563 100%)"] {
  background: none !important;
  background-image: none !important;
  -webkit-background-clip: unset !important;
  background-clip: unset !important;
  -webkit-text-fill-color: #1f2937 !important;
  color: #1f2937 !important;
  text-shadow: none !important;
}

/* Additional specific overrides if needed */
.pdf-export *[style*="background: linear-gradient(135deg, #1f2937 0%, #4b5563 100%)"] {
  background: none !important;
  background-image: none !important;
  -webkit-background-clip: unset !important;
  background-clip: unset !important;
  -webkit-text-fill-color: #1f2937 !important;
  color: #1f2937 !important;
}

/* Override all gradient text styles in PDF export */
.pdf-export h1,
.pdf-export h2,
.pdf-export h3,
.pdf-export h4,
.pdf-export h5,
.pdf-export h6,
.pdf-export .section-title,
.pdf-export .description-section h3,
.pdf-export .work-experience-section h3,
.pdf-export .education-section h3,
.pdf-export .analysis-main-heading,
.pdf-export .assessment-heading,
.pdf-export div[style*="background"],
.pdf-export div[style*="linear-gradient"],
.pdf-export [style*="background: linear-gradient"],
.pdf-export [style*="-webkit-background-clip: text"],
.pdf-export [style*="text-fill-color"] {
  background: none !important;
  background-image: none !important;
  background-clip: unset !important;
  -webkit-background-clip: unset !important;
  -webkit-text-fill-color: #1f2937 !important;
  color: #1f2937 !important;
  text-shadow: none !important;
}

/* Additional override for any element with gradient styles */
.pdf-export * {
  background-image: none !important;
  -webkit-background-clip: initial !important;
  -webkit-text-fill-color: currentColor !important;
}

/* Force text color for specific sections */
.pdf-export .description-section *,
.pdf-export .work-experience-section *,
.pdf-export .education-section *,
.pdf-export .assessment-section *,
.pdf-export .job-details-section * {
  color: #1f2937 !important;
}

/* Status Update Modal Styles */
.status-update-modal .modal-content {
  background: linear-gradient(135deg, #ffffff 0%, #f8faff 100%);
  border: none;
  border-radius: 1.5rem;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.status-update-modal .modal-header {
  background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
  padding: 1.5rem 2rem;
  border: none;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status-update-modal .modal-title {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: -0.025em;
  margin: 0;
  padding-right: 2rem;
}

.status-update-modal .modal-header .close-icon {
  color: white;
  opacity: 0.9;
  font-size: 1.25rem;
  transition: all 0.2s ease;
  cursor: pointer;
  padding: 0.75rem;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  min-width: 36px;
  margin-left: 1rem;
}

.status-update-modal .modal-header .close-icon:hover {
  opacity: 1;
  background: rgba(255, 255, 255, 0.25);
  transform: rotate(90deg);
}

.status-update-modal .modal-body {
  padding: 2rem;
}

/* Select Styles */
.status-update-modal .status-select-container {
  margin-bottom: 1.5rem;
}

.status-update-modal .status-select-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 600;
  color: #4b5563;
  margin-bottom: 0.75rem;
}

/* Custom styles for React-Select */
.status-update-modal .status-select__control {
  border: 2px solid #e5e7eb;
  border-radius: 0.75rem;
  padding: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.status-update-modal .status-select__control:hover {
  border-color: #6366f1;
}

.status-update-modal .status-select__control--is-focused {
  border-color: #6366f1;
  box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.1);
}

.status-update-modal .status-select__option {
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.status-update-modal .status-select__option--is-focused {
  background-color: #f3f4f6;
}

.status-update-modal .status-select__option--is-selected {
  background-color: #6366f1;
  color: white;
}

/* Textarea Styles */
.status-update-modal .reason-textarea {
  width: 100%;
  min-height: 120px;
  padding: 1rem;
  border: 2px solid #e5e7eb;
  border-radius: 0.75rem;
  font-size: 0.95rem;
  color: #1f2937;
  transition: all 0.2s ease;
  resize: vertical;
}

.status-update-modal .reason-textarea:focus {
  outline: none;
  border-color: #6366f1;
  box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.1);
}

/* Footer Styles */
.status-update-modal .modal-footer {
  padding: 1.5rem 2rem;
  border-top: 1px solid #f3f4f6;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

/* Button Styles */
.status-update-modal .btn-cancel {
  padding: 0.75rem 1.5rem;
  border-radius: 0.75rem;
  font-weight: 600;
  color: #6b7280;
  background: #f3f4f6;
  border: none;
  transition: all 0.2s ease;
}

.status-update-modal .btn-cancel:hover {
  background: #e5e7eb;
  color: #4b5563;
}

.status-update-modal .btn-update {
  padding: 0.75rem 2rem;
  border-radius: 0.75rem;
  font-weight: 600;
  color: white;
  background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
  border: none;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.status-update-modal .btn-update:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.2);
}

.status-update-modal .btn-update:disabled {
  background: linear-gradient(135deg, #9ca3af 0%, #6b7280 100%);
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

/* Loading Spinner */
.status-update-modal .spinner {
  width: 1.25rem;
  height: 1.25rem;
  border-width: 0.2em;
}

/* Add validation styles for form fields */
.status-update-modal .form-field-required {
  color: #ef4444;
  font-size: 0.75rem;
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.status-update-modal .status-select__control--is-invalid {
  border-color: #ef4444;
}

.status-update-modal .reason-textarea--is-invalid {
  border-color: #ef4444;
}
