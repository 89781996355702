@import "./../../abstracts/variable";

[data-theme-version="dark"] {
  background: $d-bg;
  color: $d-ctl;

  --nav-headbg: #{$dark-card};
  --sidebar-bg: #{$dark-card};
  --headerbg: #{$dark-card};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $white !important;
  }
  a.link {
    color: $d-ctd;
  }
  a.link:focus,
  a.link:hover {
    color: $info;
  }

  a {
    &:hover {
      color: $white;
    }
  }

  //Generating border classess
  @each $value in $borders {
    @if $value == "" {
      .border {
        border: 0.0625rem solid $d-border !important;
      }
    } @else {
      .border-#{$value} {
        border-#{$value}: 0.0625rem solid $d-border !important;
      }
    }
  }
}
