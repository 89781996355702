/* Base styles */
.notification-container {
  padding: 1.5rem;
}

.notification-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 2rem;
}

.grid-container {
  display: grid;
  gap: 2rem;
}

.grid-2-cols {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

/* Card styles */
.card {
  background: white;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.card-header {
  padding: 1.5rem;
}

.card-title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.025em;
}

.card-description {
  font-size: 0.875rem;
  color: #6b7280;
  margin-top: 0.375rem;
}

.card-content {
  padding: 1.5rem;
  padding-top: 0;
}

/* Button styles */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  transition: all 0.2s;
}

.btn-primary {
  background-color: #6B8BF5;
  color: white;
}

.btn-primary:hover {
  background-color: #5A7DE4;
}

.btn-outline {
  border: 1px solid #e5e7eb;
  background-color: white;
}

.btn-outline:hover {
  background-color: #f3f4f6;
}

/* Badge styles */
.badge {
  display: inline-flex;
  align-items: center;
  border-radius: 9999px;
  padding: 0.25rem 0.625rem;
  font-size: 0.75rem;
  font-weight: 600;
  transition: colors 0.2s;
}

.badge-primary {
  background-color: #6B8BF5;
  color: white;
}

.badge-secondary {
  background-color: #f3f4f6;
  color: #1f2937;
}

.badge-outline {
  border: 1px solid #e5e7eb;
  color: #1f2937;
}

/* Form styles */
.form-group {
  margin-bottom: 1rem;
}

.form-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

/* Scroll area styles */
.scroll-area {
  height: 400px;
  overflow: auto;
  padding-right: 1rem;
}

.scroll-area::-webkit-scrollbar {
  width: 8px;
}

.scroll-area::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroll-area::-webkit-scrollbar-thumb {
  background: #888;
}

/* Status indicator */
.status-indicator {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 9999px;
}

.status-connected {
  background-color: #4ADE80;
}

.status-disconnected {
  background-color: #d1d5db;
}

/* Update existing styles */
.notification-header {
  background: linear-gradient(135deg, #6B8BF5 0%, #5A7DE4 100%);
  padding: 2rem;
  border-radius: 1rem;
  width: 100%;
  margin-bottom: 1rem;
}

.notification-page-title {
  color: white;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.notification-titles p {
  color: rgba(255, 255, 255, 0.9);
}

.custom-alert {
  background: linear-gradient(135deg, #F5F7FF 0%, #EEF1FF 100%);
  border: none;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(107, 139, 245, 0.1);
}

.alert-icon-wrapper {
  background: #6B8BF5;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-cards .card {
  transition: transform 0.2s, box-shadow 0.2s;
  border: none;
  overflow: hidden;
}

.notification-cards .card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(107, 139, 245, 0.15);
}

.bg-gradient-primary {
  background: linear-gradient(135deg, #6B8BF5 0%, #5A7DE4 100%);
}

.notification-form-card, .notification-list-card {
  border: none;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s;
}

.notification-form-card:hover, .notification-list-card:hover {
  transform: translateY(-2px);
}

.section-heading {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 1.5rem;
}

/* Badge styles */
.badge {
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-weight: 500;
  transition: all 0.2s;
}

.badge-primary {
  background: linear-gradient(135deg, #6B8BF5 0%, #5A7DE4 100%);
  color: white;
}

.badge-secondary {
  background: linear-gradient(135deg, #F5F7FF 0%, #EEF1FF 100%);
  color: #5A7DE4;
}

/* Form styles */
.form-control, .select-trigger {
  border-radius: 0.75rem;
  border: 1px solid #E5E7EB;
  padding: 0.75rem 1rem;
  transition: all 0.2s;
}

.form-control:focus, .select-trigger:focus {
  border-color: #6B8BF5;
  box-shadow: 0 0 0 2px rgba(107, 139, 245, 0.2);
}

/* Button styles */
.btn-primary {
  background: linear-gradient(135deg, #6B8BF5 0%, #5A7DE4 100%);
  border: none;
  border-radius: 0.75rem;
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  transition: all 0.2s;
}

.btn-primary:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(107, 139, 245, 0.2);
}

/* Animation effects */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.notification-section {
  animation: fadeIn 0.5s ease-out;
}

/* Scroll area styles */
.scroll-area {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 1rem;
  scrollbar-width: thin;
  scrollbar-color: #6B8BF5 #F5F7FF;
}

.scroll-area::-webkit-scrollbar {
  width: 6px;
}

.scroll-area::-webkit-scrollbar-track {
  background: #F5F7FF;
  border-radius: 3px;
}

.scroll-area::-webkit-scrollbar-thumb {
  background: #6B8BF5;
  border-radius: 3px;
}

/* Slack Integration Styles */
.slack-integration-container {
  width: 100%;
  padding: 2rem;
  background: linear-gradient(135deg, #2D3436 0%, #1E2124 100%);
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.slack-status-section {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
}

.slack-logo-wrapper {
  background: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slack-logo {
  color: #1264A3; /* Slack's brand color */
}

.slack-logo-wrapper:hover {
  transform: translateY(-2px) rotate(8deg);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.slack-logo {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.slack-status-content {
  flex: 1;
}

.slack-status-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.status-indicator-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  background: rgba(255, 255, 255, 0.05);
  padding: 0.75rem 1.25rem;
  border-radius: 12px;
}

.status-indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: relative;
}

.status-indicator::after {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border-radius: 50%;
  animation: pulse 2s infinite;
}

.status-connected {
  background-color: #00F5A0;
}

.status-connected::after {
  border: 2px solid #00F5A0;
}

.status-disconnected {
  background-color: #FF6B6B;
}

.status-disconnected::after {
  border: 2px solid #FF6B6B;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  70% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.status-text {
  font-size: 0.9375rem;
  font-weight: 500;
  color: white;
  letter-spacing: 0.3px;
}

.slack-button {
  padding: 0.75rem 1.5rem;
  border-radius: 12px;
  font-size: 0.9375rem;
  font-weight: 500;
  transition: all 0.3s ease;
  border: none;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.slack-button.connect {
  background: #00F5A0;
  color: #1E2124;
}

.slack-button.connect:hover {
  background: #00E090;
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0, 245, 160, 0.25);
}

.slack-button.disconnect {
  background: rgba(255, 107, 107, 0.1);
  color: #FF6B6B;
  border: 1px solid rgba(255, 107, 107, 0.3);
}

.slack-button.disconnect:hover {
  background: rgba(255, 107, 107, 0.2);
  border-color: #FF6B6B;
}

.slack-stats, .slack-benefits {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-top: 2rem;
}

.stat-item, .benefit-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.25rem;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  font-size: 0.9375rem;
  color: white;
  transition: all 0.3s ease;
}

.stat-item:hover, .benefit-item:hover {
  background: rgba(255, 255, 255, 0.05);
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.stat-item i, .benefit-item i {
  font-size: 1.25rem;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00F5A0;
}

.benefit-item i {
  color: #FF6B6B;
}

/* Add gradient border animation */
@keyframes borderGlow {
  0% {
    border-color: rgba(255, 255, 255, 0.05);
  }
  50% {
    border-color: rgba(0, 245, 160, 0.2);
  }
  100% {
    border-color: rgba(255, 255, 255, 0.05);
  }
}

.slack-integration-container:hover {
  animation: borderGlow 2s infinite;
}

/* Enhance responsive design */
@media (max-width: 992px) {
  .slack-stats, .slack-benefits {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .slack-status-section {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .slack-status-header {
    flex-direction: column;
    gap: 1rem;
  }

  .slack-stats, .slack-benefits {
    grid-template-columns: 1fr;
  }

  .status-indicator-wrapper {
    justify-content: center;
  }
}

/* Workspace Info Styles */
.workspace-info {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.08);
  transition: all 0.3s ease;
}

.workspace-info:hover {
  background: rgba(255, 255, 255, 0.08);
  border-color: rgba(255, 255, 255, 0.12);
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.workspace-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.workspace-title {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.workspace-icon {
  width: 48px;
  height: 48px;
  background: rgba(0, 245, 160, 0.1);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.workspace-icon i {
  font-size: 1.5rem;
  color: #00F5A0;
}

.workspace-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.workspace-details h4 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: white;
  letter-spacing: -0.01em;
}

.workspace-meta {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.channel-tag, .status-tag {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.375rem 0.75rem;
  border-radius: 8px;
  font-size: 0.875rem;
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.9);
}

.channel-tag i {
  color: rgba(255, 255, 255, 0.5);
}

.status-tag i {
  color: #00F5A0;
  font-size: 0.625rem;
}

.workspace-status {
  display: flex;
  align-items: center;
}

.sync-badge {
  background: rgba(0, 245, 160, 0.1);
  color: #00F5A0;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  transition: all 0.3s ease;
}

.sync-badge:hover {
  background: rgba(0, 245, 160, 0.15);
  transform: translateY(-1px);
}

.sync-badge i {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .workspace-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .workspace-meta {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
  
  .channel-tag, .status-tag {
    width: fit-content;
  }
}

/* Notification Form Styles */
.notification-form {
  padding: 1.5rem;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(107, 139, 245, 0.1);
}

.form-section {
  background: #F8FAFF;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border: 1px solid #E5E9FF;
  transition: all 0.2s ease;
}

.form-section:hover {
  box-shadow: 0 4px 12px rgba(107, 139, 245, 0.08);
}

.form-section-header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.25rem;
  color: #1E2124;
}

.form-section-header i {
  color: #6B8BF5;
  font-size: 1.25rem;
}

.form-section-header h5 {
  margin: 0;
  font-size: 1.125rem;
  font-weight: 600;
  color: #1E2124;
}

.custom-form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  color: #4B5563;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.75rem;
}

/* Radio Group Styles */
.radio-group {
  display: flex;
  gap: 1rem;
}

.radio-option {
  flex: 1;
  padding: 1rem;
  background: white;
  border: 1px solid #E5E9FF;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #4B5563;
}

.radio-option:hover {
  border-color: #6B8BF5;
  background: #F8FAFF;
}

.radio-option.active {
  background: #6B8BF5;
  border-color: #6B8BF5;
  color: white;
}

.radio-option.active i {
  color: white;
}

/* Trigger Options Styles */
.trigger-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.trigger-option {
  padding: 1rem;
  background: white;
  border: 1px solid #E5E9FF;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #4B5563;
}

.trigger-option:hover {
  border-color: #6B8BF5;
  background: #F8FAFF;
  transform: translateY(-1px);
}

.trigger-option.active {
  background: #6B8BF5;
  border-color: #6B8BF5;
  color: white;
}

.trigger-option.active i {
  color: white;
}

/* Delivery Options Styles */
.delivery-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.delivery-option {
  padding: 1.25rem;
  background: white;
  border: 1px solid #E5E9FF;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.delivery-option:hover {
  border-color: #6B8BF5;
  background: #F8FAFF;
  transform: translateY(-1px);
}

.delivery-option.active {
  background: linear-gradient(135deg, #6B8BF5 0%, #5A7DE4 100%);
  border-color: transparent;
  color: white;
}

.delivery-option i {
  font-size: 1.5rem;
  color: #6B8BF5;
}

.delivery-option.active i {
  color: white;
}

.delivery-option-content {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.delivery-title {
  color: #1E2124;
  font-weight: 500;
}

.delivery-option.active .delivery-title {
  color: white;
}

.delivery-desc {
  color: #6B7280;
  font-size: 0.875rem;
}

.delivery-option.active .delivery-desc {
  color: rgba(255, 255, 255, 0.8);
}

/* Slack Settings Styles */
.slack-destination-options {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.slack-destination {
  padding: 1rem;
  background: white;
  border: 1px solid #E5E9FF;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #4B5563;
}

.slack-destination:hover {
  border-color: #6B8BF5;
  background: #F8FAFF;
}

.slack-destination.active {
  background: linear-gradient(135deg, #6B8BF5 0%, #5A7DE4 100%);
  border-color: transparent;
  color: white;
}

/* Channel Select Styles */
.channel-select-wrapper {
  position: relative;
}

.channel-prefix {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #6B7280;
  z-index: 1;
}

.channel-select {
  width: 100%;
  height: auto !important;
  padding: 0.875rem 1rem !important;
  background-color: white !important;
  border: 1px solid #E5E9FF !important;
  border-radius: 12px !important;
  color: #1E2124 !important;
  font-size: 0.9375rem !important;
  transition: all 0.2s ease;
  cursor: pointer;
}

.channel-select:hover {
  border-color: #6B8BF5 !important;
}

.channel-select:focus {
  box-shadow: 0 0 0 3px rgba(107, 139, 245, 0.2) !important;
  border-color: #6B8BF5 !important;
}

/* Style the placeholder */
.channel-select option[value=""] {
  color: #6B7280;
}

/* Remove the default focus outline */
.channel-select:focus-visible {
  outline: none;
}

/* Custom arrow icon */
.channel-select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") !important;
  background-repeat: no-repeat !important;
  background-position: right 1rem center !important;
  padding-right: 2.5rem !important;
}

/* Improved form section headers */
.form-section-header {
  margin-bottom: 1.5rem;
}

.form-section-header h5 {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1E2124;
}

.form-section-header i {
  width: 32px;
  height: 32px;
  background: rgba(107, 139, 245, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6B8BF5;
}

/* Submit Button Styles */
.submit-button {
  width: 100%;
  padding: 1rem;
  background: linear-gradient(135deg, #6B8BF5 0%, #5A7DE4 100%);
  border: none;
  border-radius: 12px;
  color: white;
  font-weight: 500;
  font-size: 0.9375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(107, 139, 245, 0.1);
}

.submit-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(107, 139, 245, 0.2);
}

.submit-button i {
  font-size: 1.125rem;
}

/* Add subtle animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.form-section {
  animation: fadeIn 0.3s ease-out forwards;
  animation-delay: calc(var(--index) * 0.1s);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .delivery-options,
  .slack-destination-options {
    grid-template-columns: 1fr;
  }
  
  .notification-form {
    padding: 1rem;
  }
}

/* Channel Dropdown Styles */
.channel-dropdown {
  width: 100%;
}

.channel-dropdown-toggle {
  width: 100%;
  padding: 0.875rem 1rem !important;
  background: white !important;
  border: 1px solid #E5E9FF !important;
  border-radius: 12px !important;
  display: flex !important;
  align-items: center;
  justify-content: flex-start !important;
  text-align: left;
  color: #1E2124 !important;
  font-size: 0.9375rem;
  transition: all 0.2s ease;
  position: relative;
  padding-left: 2.5rem !important;
}

/* Style for placeholder text */
.channel-dropdown-toggle span {
  color: #6B7280;
}

/* Style when value is selected */
.channel-dropdown-toggle.has-value span {
  color: #1E2124;
}

.channel-dropdown-toggle::after {
  position: absolute !important;
  right: 1rem !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  border-top: 0.3em solid #6B7280 !important;
}

.channel-dropdown-toggle:hover {
  border-color: #6B8BF5 !important;
  background: white !important;
}

.channel-dropdown-toggle:focus,
.channel-dropdown-toggle.show {
  box-shadow: 0 0 0 3px rgba(107, 139, 245, 0.2) !important;
  border-color: #6B8BF5 !important;
  background: white !important;
}

.channel-prefix {
  position: absolute;
  left: 1rem;
  color: #6B7280;
  font-size: 0.875rem;
}

.channel-dropdown-menu {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #E5E9FF;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 0.5rem !important;
}

.channel-dropdown-item {
  padding: 0.75rem 1rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #1E2124;
  transition: all 0.2s ease;
}

.channel-dropdown-item i {
  color: #6B7280;
  font-size: 0.875rem;
}

.channel-dropdown-item:hover {
  background-color: #F8FAFF;
  color: #6B8BF5;
}

.channel-dropdown-item:hover i {
  color: #6B8BF5;
}

.channel-dropdown-item.active {
  background-color: #6B8BF5 !important;
  color: white;
}

.channel-dropdown-item.active i {
  color: white;
}

/* Animation for dropdown */
.dropdown-menu.show {
  animation: dropdownFadeIn 0.2s ease-out;
}

@keyframes dropdownFadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Webhook Form Styles */
.form-input {
  width: 100%;
  padding: 0.875rem 1rem;
  background: white;
  border: 1px solid #E5E9FF;
  border-radius: 12px;
  color: #1E2124;
  font-size: 0.9375rem;
  transition: all 0.2s ease;
}

.form-input:hover {
  border-color: #6B8BF5;
}

.form-input:focus {
  outline: none;
  border-color: #6B8BF5;
  box-shadow: 0 0 0 3px rgba(107, 139, 245, 0.2);
}

.form-textarea {
  width: 100%;
  padding: 0.875rem 1rem;
  background: white;
  border: 1px solid #E5E9FF;
  border-radius: 12px;
  color: #1E2124;
  font-size: 0.9375rem;
  resize: vertical;
  min-height: 100px;
  transition: all 0.2s ease;
}

.form-textarea:hover {
  border-color: #6B8BF5;
}

.form-textarea:focus {
  outline: none;
  border-color: #6B8BF5;
  box-shadow: 0 0 0 3px rgba(107, 139, 245, 0.2);
}

.method-options {
  display: flex;
  gap: 1rem;
}

.method-option {
  padding: 0.75rem 1.25rem;
  background: white;
  border: 1px solid #E5E9FF;
  border-radius: 12px;
  color: #1E2124;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
}

.method-option:hover {
  border-color: #6B8BF5;
  background: #F8FAFF;
}

.method-option.active {
  background: #6B8BF5;
  border-color: #6B8BF5;
  color: white;
}

.auth-options {
  display: flex;
  gap: 1rem;
}

.auth-option {
  flex: 1;
  padding: 0.875rem 1rem;
  background: white;
  border: 1px solid #E5E9FF;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #1E2124;
}

.auth-option:hover {
  border-color: #6B8BF5;
  background: #F8FAFF;
}

.auth-option.active {
  background: #6B8BF5;
  border-color: #6B8BF5;
  color: white;
}

.auth-option i {
  color: #6B8BF5;
}

.auth-option.active i {
  color: white;
}

.text-muted {
  color: #6B7280 !important;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

/* Dashboard Header Styles */
.dashboard-section-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #E5E9FF;
}

.header-icon {
  width: 48px;
  height: 48px;
  min-width: 48px;
  background: linear-gradient(135deg, #6B8BF5 0%, #5A7DE4 100%);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-icon i {
  font-size: 1.25rem;
  color: white;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.header-content h3 {
  margin: 0 !important;
  font-size: 1.5rem;
  font-weight: 600;
  color: #1E2124;
}

.header-content span {
  color: #6B7280;
  font-size: 0.9375rem;
}

/* Responsive adjustments */
@media (max-width: 576px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
  }
}

/* Trigger List Styles */
.triggers-list {
  display: grid;
  gap: 1rem;
}

.trigger-card {
  background: white;
  border: 1px solid #E5E9FF;
  border-radius: 12px;
  padding: 1.25rem;
  transition: all 0.2s ease;
}

.trigger-card:hover {
  box-shadow: 0 4px 12px rgba(107, 139, 245, 0.1);
  transform: translateY(-2px);
}

.trigger-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.trigger-type {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.type-icon {
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.type-icon.slack {
  background: #4A154B;
  color: white;
}

.type-icon.webhook {
  background: #6B8BF5;
  color: white;
}

.type-icon i {
  font-size: 1.125rem;
}

.type-label {
  font-weight: 500;
  color: #1E2124;
}

.trigger-actions {
  display: flex;
  gap: 0.5rem;
}

.delete-button {
  width: 32px;
  height: 32px;
  border: 1px solid #FDA29B;
  background: #FFF1F0;
  color: #F04438;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.delete-button:hover {
  background: #F04438;
  color: white;
  border-color: #F04438;
}

.trigger-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.trigger-info h4 {
  margin: 0 0 0.5rem 0;
  font-size: 1rem;
  font-weight: 600;
  color: #1E2124;
}

.trigger-for {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #6B7280;
  font-size: 0.875rem;
}

.trigger-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-top: 0.75rem;
  border-top: 1px solid #E5E9FF;
}

.detail-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #6B7280;
  font-size: 0.875rem;
}

.detail-item i {
  width: 16px;
  color: #6B8BF5;
}

/* Empty State Styles */
.empty-triggers {
  text-align: center;
  padding: 3rem 1rem;
  color: #6B7280;
}

.empty-icon {
  width: 64px;
  height: 64px;
  background: #F8FAFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
}

.empty-icon i {
  font-size: 1.5rem;
  color: #6B8BF5;
}

.empty-triggers h4 {
  margin: 0 0 0.5rem 0;
  color: #1E2124;
  font-weight: 600;
}

.empty-triggers p {
  margin: 0;
  color: #6B7280;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .trigger-content {
    flex-direction: column;
  }
  
  .trigger-details {
    padding-top: 0.75rem;
    margin-top: 0.75rem;
    border-top: 1px solid #E5E9FF;
  }
} 