/* Modern Tab Styles */
.nav-tabs {
  border: none;
  margin-bottom: 0;
  position: relative;
  background: linear-gradient(120deg, #f8f9fa, #f0f4ff);
  padding: 1rem 1rem 0;
  border-radius: 12px 12px 0 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.03);
}

.nav-tabs .nav-item {
  margin: 0 0.5rem;
}

.nav-tabs .nav-link {
  border: none;
  padding: 0.85rem 1.75rem;
  border-radius: 10px 10px 0 0;
  font-weight: 500;
  color: #8890a6;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  background: rgba(236, 238, 255, 0.4);
  overflow: hidden;
}

/* Hover effect */
.nav-tabs .nav-link:hover {
  color: #4f46e5;
  background: rgba(236, 238, 255, 0.8);
}

/* Focus effect */
.nav-tabs .nav-link:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.2);
}

/* Active tab styling */
.nav-tabs .nav-link.chrome-tab-active {
  color: #4f46e5;
  background: #ffffff;
  font-weight: 600;
  box-shadow: 0 -4px 6px -2px rgba(79, 70, 229, 0.1);
  margin-bottom: -1px;
  padding-bottom: calc(0.85rem + 1px);
}

/* Gradient overlay for active tab */
.nav-tabs .nav-link.chrome-tab-active::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: linear-gradient(90deg, 
    #4f46e5,
    #6366f1,
    #818cf8,
    #6366f1,
    #4f46e5
  );
  animation: gradientShift 3s ease infinite;
  background-size: 200% auto;
}

/* Tab content styling */
.tab-content {
  background: #ffffff;
  border-radius: 0 0 12px 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  padding: 2rem;
  position: relative;
  z-index: 1;
}

/* Enhanced fade animation for tab content */
.tab-content {
  animation: slideUpFade 0.4s ease-out;
}

@keyframes slideUpFade {
  from {
    opacity: 0;
    transform: translateY(8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes gradientShift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Glow effect for active tab */
.nav-tabs .nav-link.chrome-tab-active::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at center top,
    rgba(79, 70, 229, 0.08),
    transparent 70%
  );
}

/* Hover glow effect */
.nav-tabs .nav-link:not(.chrome-tab-active):hover::after {
  content: '';
  position: absolute;
  inset: 0;
  background: radial-gradient(
    circle at center,
    rgba(79, 70, 229, 0.1),
    transparent 70%
  );
  opacity: 0;
  animation: pulseGlow 1s ease-in-out infinite;
}

@keyframes pulseGlow {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

/* For Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  /* margin: 0; */
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
