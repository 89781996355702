//Google font
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");
@import url("https://fonts.googleapis.com/css?family=Nunito:400,600,700");

// @import url('./../icons/helveticaNeue/css/helveticaNeue.css');
@import url("./../icons/simple-line-icons/css/simple-line-icons.css");
@import url("./../icons/font-awesome/css/all.min.css");
@import url("./../icons/material-design-iconic-font/css/materialdesignicons.min.css");
@import url("./../icons/themify-icons/css/themify-icons.css");
@import url("./../icons/line-awesome/css/line-awesome.min.css");
@import url("./../icons/avasta/css/style.css");
@import url("./../icons/flaticon/flaticon.css");
@import url("./../icons/flaticon_1/flaticon_1.css");
@import url("./../icons/icomoon/icomoon.css");
@import url("./../icons/bootstrap-icons/font/bootstrap-icons.css");
