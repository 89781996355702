/////////////////
// Header
/////////////////
.header {
  // width: 100%;
  height: var(--height);
  z-index: 1;
  position: relative;
  padding: 0rem;
  background: var(--headerbg);
  z-index: 3;
  padding-left: var(--width);
  padding-top: 0rem;
  transition: all 0.2s ease;

  .header-content {
    height: 100%;
    padding-left: 7rem;
    padding-right: 1.875rem;
    align-items: center;
    display: flex;

    @include respond("laptop") {
      padding-left: 5rem;
    }
    @include respond("tab-land") {
      padding-left: 4rem;
    }
    @include custommq($max: 47.9375rem) {
      padding-left: 3.75rem;
      padding-right: 0.938rem;
    }
    @include respond("phone") {
      padding-right: 0.538rem;
    }
  }
  .navbar {
    padding: 0;
    height: 100%;
    width: 100%;
    .navbar-collapse {
      height: 100%;
      width: 100%;
    }
  }
  @include respond("laptop") {
    height: 5.5rem;
  }
  @include respond("tab-land") {
    height: 5rem;
  }
  @include respond("phone-land") {
    padding-top: 0;
  }
}

////////////////////////
// CSS Pulse Effect
////////////////////////
@mixin circle($circleSize) {
  width: $circleSize;
  height: $circleSize;
  border-radius: $circleSize/2;
}

/* pulse in SVG */

svg.pulse-svg {
  overflow: visible;
  .first-circle {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation: pulse-me 3s linear infinite;
    animation: pulse-me 3s linear infinite;
    fill: var(--primary);
  }
  .second-circle {
    @extend .first-circle;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  .third-circle {
    @extend .first-circle;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }
}

/* pulse in CSS */

.pulse-css {
  @include circle(1rem);
  border-radius: 3.5rem;
  height: 1.25rem;
  position: absolute;
  background: #fe8630;
  right: 0.375rem;
  top: 0.3125rem;
  border: 0.25rem solid $white;
  width: 1.25rem;
  &:after,
  &:before {
    content: "";
    @include circle(1rem);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -0.2rem;
    background-color: #d8b9c3;
    margin: auto;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation: pulse-me 3s linear infinite;
    animation: pulse-me 3s linear infinite;
    @at-root [direction="rtl"] & {
      left: auto;
      right: -0.2rem;
    }
  }
  @include respond("laptop") {
    height: 1rem;
    width: 1rem;
  }
}

@-webkit-keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.09;
  }
  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.09;
  }
  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}

[data-sidebar-style="full"],
[data-sidebar-style="overlay"] {
  .header {
    width: 100%;
    @include respond("laptop") {
      width: 100%;
      padding-left: 17rem;
    }
    @include custommq($max: 63.9375rem) {
      width: 100%;
      padding-left: 5rem;
    }
  }
}

[data-sidebar-style="mini"] {
  .header {
    width: 100%;
    height: 6.5rem;
    padding-left: 7.5rem;
    @include custommq($max: 63.9375rem) {
      height: 5.5rem;
      padding-left: 6.5rem;
    }
  }
}

[data-sidebar-style="compact"] {
  .header {
    width: 100%;
    padding-left: 12.5rem;
    .header-content {
      padding-left: 2.5rem;
    }
  }
}

[data-header-position="fixed"] {
  .header {
    position: fixed;
    top: 0;
    width: 100%;
  }
  .content-body {
    padding-top: var(--height);
    @include respond("laptop") {
      padding-top: 5.5rem;
    }
    @include respond("tab-land") {
      padding-top: 5rem;
    }
  }
  .dlabnav {
    margin-top: 0;
  }
}
[data-sidebar-style="compact"][data-header-position="fixed"][data-container="boxed"][data-layout="vertical"] {
  .header {
    width: 74.9375rem;
  }
}
[data-sidebar-style="modern"] {
  .header {
    width: 100%;
    padding-left: 11.9rem;
  }
  &[data-layout="horizontal"] {
    .nav-header .brand-logo {
      justify-content: start;
    }
    .header .header-content {
      padding-left: 1.875rem;
    }
  }
}
