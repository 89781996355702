.global-loader {
  width: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}

.no-break {
  page-break-inside: avoid;
}

.can-break {
  page-break-inside: auto;
}

.page-break-before {
  page-break-before: always;
}

.no-page-break-before {
  page-break-before: avoid;
}

.page-break-after {
  page-break-after: always;
}

.no-page-break-after {
  page-break-after: avoid;
}

.global-loader-title {
  color: white;
}

.global-loader-full {
  height: 100%;
}

.global-loader-entity {
  width: 5rem !important;
  height: 5rem !important;
  background-color: var(--primary);
}

@media screen and (max-width: 695px) {
  .global-loader {
    height: 100%;
  }
}

@media screen and (min-width: 695px) {
  .global-loader {
    height: 100%;
  }
}