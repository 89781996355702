/*
===================================
    list view
===================================*/

.new-arrival-content {
  .item {
    font-size: 0.75rem;
    color: $dark;
  }
  h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.625rem;
    a {
      color: $black;
    }
  }
  .price {
    font-weight: 500;
    color: var(--primary);
    font-size: 1.125rem;
    margin-bottom: 0;
    float: right;
    @include respond("phone") {
      float: none;
      margin-top: 0.625rem;
    }
  }
  .discount {
    font-size: 14px;
    margin-right: 8px;
    opacity: 0.8;
    color: var(--primary);
  }
  p {
    font-size: 0.875rem;
    color: $d-ctl;
    margin-bottom: 0.375rem;
    line-height: 1.5rem;
  }
  .text-content {
    margin-top: 1.125rem;
  }
}
.new-arrival-content.text-center .price {
  float: unset !important;
}

.success-icon {
  color: $success;
  font-size: 1rem;
}

.comment-review {
  margin-bottom: 0.9375rem;
  display: table;
  width: 100%;
  .client-review {
    color: $d-ctl;
    padding-right: 1.25rem;
    text-decoration: underline !important;
    font-size: 0.875rem;
  }
  .span {
    color: $d-ctl;
    font-size: 0.875rem;
  }
  @include respond("phone") {
    margin-bottom: 0;
  }
}

.star-rating li {
  display: inline-block;
  i {
    color: gold;
  }
}
.rating-stars ul > li.star > i.fa {
  font-size: 1.375rem;
}
