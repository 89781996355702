:root {
  --pastel-primary: #a5d8ff;
  --pastel-secondary: #ffd6e7;
  --pastel-success: #c3fae8;
  --pastel-warning: #fff3bf;
  --pastel-card-bg: rgba(255, 255, 255, 0.95);
  --card-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  --transition-speed: 0.3s;
}

.billing {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 5rem;
}

.billing-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(135deg, var(--pastel-primary), var(--pastel-secondary));
  padding: 2.5rem;
  border-radius: 1rem;
  box-shadow: var(--card-shadow);
  transition: transform var(--transition-speed);
}

.billing-header:hover {
  transform: translateY(-2px);
}

.billing-titles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.billing-page-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #2b3674;
  margin-bottom: 0.5rem;
}

.billing-cards {
  height: 160px;
}

.billing-current-credits {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--primary);
  border-radius: 2rem;
  color: white;
}

.amount-due-card {
  background: linear-gradient(135deg, #4e95ff, #6f42c1);
  border-radius: 1rem;
  box-shadow: 
    0 12px 40px rgba(78, 149, 255, 0.15),
    inset 0 -1px 0 rgba(255, 255, 255, 0.1);
  transition: all var(--transition-speed);
  overflow: hidden;
  width: 100%;
  position: relative;
}

.amount-due-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3z' fill='rgba(255,255,255,0.05)' fill-rule='evenodd'/%3E%3C/svg%3E");
  opacity: 0.5;
}

.amount-due-card:hover {
  transform: translateY(-3px);
  box-shadow: 
    0 20px 50px rgba(78, 149, 255, 0.2),
    inset 0 -1px 0 rgba(255, 255, 255, 0.2);
}

.amount-due-card .card-body {
  padding: 2.5rem;
  color: white;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.amount-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.amount-value {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.amount-label {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  letter-spacing: 0.5px;
}

.amount-number {
  font-size: 3rem;
  font-weight: 700;
  color: white;
  letter-spacing: -1px;
  line-height: 1;
}

.payment-notice {
  background: rgba(255, 255, 255, 0.15);
  padding: 0.6rem 1.2rem;
  border-radius: 2rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: white;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.payment-message-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  padding: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  margin: 1rem 0;
}

.payment-message {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  margin: 0;
  color: #ffffff !important;
  font-size: 1.1rem;
  line-height: 1.5;
  font-weight: 500;
}

.payment-message i {
  color: #ffffff;
  font-size: 1.2rem;
  margin-top: 0.2rem;
}

.payment-warning {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  margin: 0;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  color: #ffd6e7;
  font-size: 1rem;
  line-height: 1.5;
}

.payment-warning i {
  color: #ffd6e7;
  font-size: 1.2rem;
  margin-top: 0.2rem;
}

.payment-button {
  background: #ffffff !important;
  color: #4e95ff !important;
  border: none !important;
  border-radius: 0.75rem !important;
  padding: 1.25rem !important;
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 0.75rem !important;
  transition: all var(--transition-speed) !important;
  width: 100% !important;
}

.payment-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  background: #ffffff !important;
  color: #6f42c1 !important;
}

.payment-button:disabled {
  background: rgba(255, 255, 255, 0.3) !important;
  color: rgba(255, 255, 255, 0.6) !important;
  cursor: not-allowed;
}

.payment-button i {
  color: #4e95ff;
  font-size: 1.1rem;
}

.payment-button:hover:not(:disabled) i {
  color: #6f42c1;
}

.payment-button:disabled i {
  color: rgba(255, 255, 255, 0.6);
}

.amount-due-card .text-danger {
  color: white !important;
  font-size: 2.5rem;
  font-weight: 700;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  letter-spacing: -0.5px;
}

.amount-due-card .payment-notice {
  display: inline-flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.15);
  padding: 0.6rem 1.2rem;
  border-radius: 0.75rem;
  font-size: 0.9rem;
  font-weight: 600;
  color: white;
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.amount-due-card .payment-notice i {
  color: white;
  margin-right: 0.5rem;
}

.amount-due-card .payment-message {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1rem;
  line-height: 1.6;
}

.amount-due-card .payment-message .text-danger {
  color: #ffd6e7 !important;
  font-size: 1rem;
  font-weight: 500;
  display: inline;
  margin-left: 0.5rem;
}

.amount-due-card .btn {
  background: white;
  color: #4e95ff;
  border: none;
  border-radius: 0.75rem;
  padding: 1rem 1.2rem;
  font-weight: 600;
  transition: all var(--transition-speed);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.amount-due-card .btn:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  background: white;
  color: #6f42c1;
}

.amount-due-card .btn:disabled {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.6);
  cursor: not-allowed;
}

.amount-due-card .btn i {
  font-size: 1.1rem;
  transition: transform var(--transition-speed);
}

.amount-due-card .btn:hover i {
  transform: translateX(3px);
}

.amount-due-card .h5 {
  font-size: 1.2rem;
  color: #495057;
}

.amount-due-card .h5 .text-danger {
  font-size: inherit;
  display: inline;
}

.amount-due-card .btn {
  background: var(--pastel-primary);
  border: none;
  border-radius: 0.75rem;
  padding: 1rem 1.2rem;
  color: #fff;
  transition: all var(--transition-speed);
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.amount-due-card .btn:hover:not(:disabled) {
  background: #4e95ff;
  transform: scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.amount-due-card .btn:disabled {
  background: #e9ecef;
  color: #adb5bd;
  cursor: not-allowed;
}

.amount-due-card .btn i {
  font-size: 1.1rem;
}

.amount-due-card .payment-notice {
  display: inline-flex;
  align-items: center;
  background: rgba(165, 216, 255, 0.15);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  font-weight: 600;
  color: #dddddd;
  margin-top: 0.5rem;
}

.amount-due-card .payment-notice i {
  color: #dddddd;
}

.amount-due-card .payment-message {
  line-height: 1.6;
  font-size: 1rem;
  color: #495057;
}

.amount-due-card .payment-message .text-danger {
  font-size: 1rem;
  display: block;
  margin-top: 0.5rem;
}

@media screen and (min-width: 1600px) {
  .billing-cards {
    height: 180px;
  }
}

.card {
  border: none !important;
  border-radius: 1rem !important;
  box-shadow: var(--card-shadow);
  transition: all var(--transition-speed);
  overflow: hidden;
}

.card:hover {
  transform: translateY(-3px);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
}

.card-body {
  padding: 1.8rem !important;
}

.bg-primary {
  background: linear-gradient(135deg, #4e95ff, #6f42c1) !important;
}

/* Table Styles */
.table {
  background: var(--pastel-card-bg);
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: var(--card-shadow);
}

.table thead th {
  background: #f8f9fa;
  border-bottom: none;
  padding: 1rem;
  font-weight: 600;
  color: #2b3674;
}

.table tbody tr {
  transition: background-color var(--transition-speed);
}

.table tbody tr:hover {
  background-color: #f8f9fa;
}

/* Transaction ID styling */
.transaction-id {
  font-family: 'Monaco', monospace;
  background: #f8f9fa;
  padding: 0.4rem 0.8rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.copy-button {
  background: transparent;
  border: none;
  color: #6f42c1;
  padding: 0.2rem;
  border-radius: 0.3rem;
  transition: all var(--transition-speed);
}

.copy-button:hover {
  background: rgba(111, 66, 193, 0.1);
  transform: scale(1.1);
}

/* Pagination styling */
.pagination {
  gap: 0.5rem;
}

.page-link {
  border: none !important;
  border-radius: 0.5rem !important;
  padding: 0.5rem 1rem;
  color: #2b3674;
  transition: all var(--transition-speed);
}

.page-item.active .page-link {
  background: linear-gradient(135deg, var(--pastel-primary), #4e95ff);
  transform: scale(1.1);
}

/* Search bar styling */
.search-container {
  position: relative;
  display: flex;
  align-items: center;
}

.search-input {
  background: var(--pastel-card-bg);
  border: 1px solid #e9ecef;
  border-radius: 0.75rem;
  height: 40px;
  padding: 0.6rem 1rem 0.6rem 2.5rem;
  width: 300px;
  transition: all var(--transition-speed) ease;
  font-size: 0.9rem;
  color: #2b3674;
}

/* When input has focus */
.search-input:focus {
  width: 500px;
  border-color: var(--pastel-primary);
  box-shadow: 0 0 0 3px rgba(165, 216, 255, 0.2);
  background: white;
}

/* When input has value but no focus */
.search-input[data-has-value="true"] {
  background: rgba(165, 216, 255, 0.05);
  border-color: var(--pastel-primary);
}

/* When input has both focus and value */
.search-input[data-has-value="true"]:focus {
  background: white;
  border-color: var(--pastel-primary);
  box-shadow: 0 0 0 3px rgba(165, 216, 255, 0.3);
}

.search-icon {
  position: absolute;
  left: 0.8rem;
  top: 50%;
  transform: translateY(-50%);
  color: #6f42c1;
  font-size: 0.9rem;
  pointer-events: none; /* Ensures the icon doesn't interfere with input */
  transition: color var(--transition-speed);
}

/* Change icon color when input has focus or value */
.search-input:focus + .search-icon,
.search-input[data-has-value="true"] + .search-icon {
  color: var(--pastel-primary);
}

/* Add these styles for the Redeem Coupon section */
.redeem-coupon-card {
  background: var(--pastel-card-bg);
  border-radius: 1rem;
  box-shadow: var(--card-shadow);
  transition: all var(--transition-speed);
  overflow: hidden;
}

.redeem-coupon-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
}

.coupon-input {
  border: 1px solid #e9ecef;
  border-radius: 0.75rem;
  padding: 0.6rem 1.2rem;
  transition: all var(--transition-speed);
  height: 40px;
}

.coupon-input:focus {
  box-shadow: 0 0 0 3px rgba(255, 214, 231, 0.3);
  border-color: var(--pastel-secondary);
}

.redeem-button {
  background: linear-gradient(135deg, var(--pastel-success), var(--pastel-warning));
  border: none;
  border-radius: 0.75rem;
  padding: 0.6rem 1.2rem;
  color: #2b3674;
  transition: all var(--transition-speed);
  height: 40px;
  display: flex !important;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
  font-size: 0.9rem;
}

.redeem-button i {
  font-size: 0.9rem;
}

.redeem-button:disabled {
  background: #e9ecef;
  color: #adb5bd;
  cursor: not-allowed;
}

.redeem-button:hover:not(:disabled) {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

/* Update the form container */
.coupon-form {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

/* Add these styles for headings */
.section-heading {
  font-size: 1.8rem;
  font-weight: 700;
  color: #2b3674;
  margin-bottom: 1rem;
  position: relative;
  padding-bottom: 0.5rem;
}

.section-heading::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 4px;
  background: var(--pastel-primary);
  border-radius: 2px;
}

.section-subheading {
  font-size: 1.2rem;
  font-weight: 500;
  color: #495057;
  margin-bottom: 1.5rem;
}

/* Option 1: Modern Pill Design */
.credits-value {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #ff9a9e, #fad0c4);
  padding: 0.4rem 1rem;
  border-radius: 2rem;
  font-weight: 600;
  color: #2b3674;
  min-width: 80px;
  box-shadow: 0 2px 8px rgba(255, 154, 158, 0.3);
  transition: all var(--transition-speed);
}

/* Option 2: Circular Design */
.credits-value-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #ffd6e7, #ffc3c3);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-weight: 600;
  color: #2b3674;
  box-shadow: 0 2px 8px rgba(255, 214, 231, 0.3);
  transition: all var(--transition-speed);
}

/* Option 3: Modern Badge Design */
.credits-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 107, 107, 0.1);
  padding: 0.4rem 0.8rem;
  border-radius: 0.5rem;
  font-weight: 600;
  color: #ff6b6b;
  border: 1.5px solid rgba(255, 107, 107, 0.2);
  min-width: 60px;
  transition: all var(--transition-speed);
}

/* Hover effects for all options */
.credits-value:hover,
.credits-value-circle:hover,
.credits-badge:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 154, 158, 0.4);
}

/* Animation for negative values */
@keyframes pulseRed {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.negative-value {
  animation: pulseRed 2s infinite;
}

.custom-alert {
  background: linear-gradient(135deg, #c3fae8, #a5d8ff);
  border-radius: 12px;
  padding: 1.5rem;
  transition: all 0.3s ease;
  border: 1px solid rgba(165, 216, 255, 0.3);
  margin: 0;
  box-shadow: 0 4px 15px rgba(165, 216, 255, 0.15);
  position: relative;
  overflow: hidden;
}

.alert {
  margin-bottom: 0px !important;
}

.alert-icon-wrapper {
  background: #4e95ff;
  color: white;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 15px rgba(78, 149, 255, 0.2);
}

.alert-content {
  color: #2b3674;
  font-size: 1.1rem;
  line-height: 1.5;
  font-weight: 500;
}

.custom-alert:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 25px rgba(165, 216, 255, 0.25);
}

/* Add subtle pattern overlay */
.custom-alert::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, 
    rgba(255, 255, 255, 0.1) 25%, 
    transparent 25%, 
    transparent 50%, 
    rgba(255, 255, 255, 0.1) 50%, 
    rgba(255, 255, 255, 0.1) 75%, 
    transparent 75%, 
    transparent);
  background-size: 10px 10px;
  opacity: 0.5;
}

/* Notification Preferences Section */
.notification-card {
  background: var(--pastel-card-bg);
  border-radius: 1.5rem !important;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
}

.notification-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-bottom: 2.5rem;
}

.notification-item {
  background: white;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: all var(--transition-speed);
  border: 1px solid rgba(0, 0, 0, 0.05);
  max-width: 100%;
}

.notification-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.08);
}

.notification-header {
  display: flex;
  align-items: flex-start;
  gap: 1.25rem;
  margin-bottom: 1.5rem;
}

.notification-icon {
  width: 52px;
  height: 52px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  color: white;
  flex-shrink: 0;
  margin-top: 4px;
}

.notification-icon.credits { background: linear-gradient(135deg, #4e95ff, #6f42c1); }
.notification-icon.amount { background: linear-gradient(135deg, #2ecc71, #27ae60); }
.notification-icon.report { background: linear-gradient(135deg, #f1c40f, #f39c12); }
.notification-icon.security { background: linear-gradient(135deg, #e74c3c, #c0392b); }
.notification-icon.reminder { background: linear-gradient(135deg, #9b59b6, #8e44ad); }
.notification-icon.auto { background: linear-gradient(135deg, #1abc9c, #16a085); }

.notification-title {
  flex-grow: 1;
  padding-right: 1rem;
  padding-top: 4px;
}

.notification-title h4 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #2b3674;
  margin-bottom: 0.3rem;
}

.notification-title p {
  font-size: 0.9rem;
  color: #6c757d;
  margin: 0;
}

/* Threshold Input Container */
.threshold-input {
  margin-top: 1.25rem;
  margin-left: 76px;
  display: flex;
  align-items: center;
  gap: 16px;
}

/* Input Wrapper */
.input-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  padding: 8px 16px;
  min-width: 120px;
  transition: all 0.2s ease;
}

.input-wrapper:hover {
  background: #fff;
  border-color: #cbd5e1;
}

.input-wrapper:focus-within {
  background: #fff;
  border-color: #4e95ff;
  box-shadow: 0 0 0 3px rgba(78, 149, 255, 0.1);
}

/* Currency Symbol */
.input-wrapper .currency {
  color: #64748b;
  font-weight: 500;
  font-size: 0.95rem;
  margin-right: 4px;
}

/* Input Field */
.input-wrapper input {
  border: none;
  background: transparent;
  padding: 0;
  width: 60px;
  font-size: 0.95rem;
  font-weight: 500;
  color: #1e293b;
}

.input-wrapper input:focus {
  outline: none;
}

/* Label Text */
.threshold-label {
  color: #64748b;
  font-size: 0.95rem;
  font-weight: 500;
}

/* Input Type Styles */
.input-wrapper.credits {
  background: rgba(78, 149, 255, 0.04);
  border-color: rgba(78, 149, 255, 0.1);
}

.input-wrapper.amount {
  background: rgba(46, 204, 113, 0.04);
  border-color: rgba(46, 204, 113, 0.1);
}

.input-wrapper.days {
  background: rgba(155, 89, 182, 0.04);
  border-color: rgba(155, 89, 182, 0.1);
}

.notification-actions {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  border-top: 1px solid #dee2e6;
}

.save-notifications {
  background: linear-gradient(135deg, #4e95ff, #6f42c1) !important;
  border: none !important;
  padding: 0.8rem 1.5rem !important;
  border-radius: 0.75rem !important;
  font-weight: 600 !important;
  display: flex !important;
  align-items: center !important;
  gap: 0.5rem !important;
  transition: all var(--transition-speed) !important;
}

.save-notifications:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(78, 149, 255, 0.3);
}

.save-notifications i {
  font-size: 1rem;
}

/* Custom Switch Styling */
.custom-switch {
  position: relative;
  height: 28px;
  width: 50px;
  margin-left: auto;
  flex-shrink: 0;
  margin-top: 8px;
}

.custom-switch .form-check-input {
  height: 28px;
  width: 50px !important;
  margin: 0;
  cursor: pointer;
  background-color: #edf2f9;
  border: 2px solid #e0e6ed;
  position: relative;
}

/* Active state highlighting */
.custom-switch .form-check-input:checked {
  background: linear-gradient(135deg, #4e95ff, #6f42c1) !important;
  border-color: transparent !important;
}

/* Switch knob */
.custom-switch .form-check-input::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.custom-switch .form-check-input:checked::before {
  left: 24px;
  background: #ffffff;
}

/* Adjust header layout */
.notification-header {
  display: flex;
  align-items: flex-start;
  gap: 1.25rem;
  margin-bottom: 1.5rem;
}

.notification-title {
  flex-grow: 1;
  padding-right: 1rem;
  padding-top: 4px;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .notification-grid {
    grid-template-columns: 1fr;
  }
}

/* Optional: Add transition for switch background */
.custom-switch .form-check-input {
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

/* Animation for Save Button */
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.save-notifications:active {
  animation: pulse 0.3s ease;
}