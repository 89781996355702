.jsgrid-grid-body,
.dataTables_scrollBody,
.table-responsive {
  &::-webkit-scrollbar {
    background-color: #f5f5f5;
    width: 0.5rem;
    height: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
    border-radius: 0.625rem;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.625rem;
    -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
    background-color: var(--primary);
  }
}
