.social-icon {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 0.25rem;
  text-align: center;
  background: $body-bg;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;

  i {
    // font-size: 0.875rem;
    color: $white;
  }
  &.youtube {
    background: $youtube;
  }
  &.facebook {
    background: $facebook;
  }
  &.twitter {
    background: $twitter;
  }
}
.social-graph-wrapper {
  text-align: center;
  padding: 1.25rem;
  position: relative;
  color: $white;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;

  &.widget-facebook {
    background-color: $facebook;
  }
  &.widget-twitter {
    background-color: $twitter;
  }
  &.widget-linkedin {
    background-color: $linkedin;
  }
  &.widget-googleplus {
    background-color: $google-plus;
  }
  .s-icon {
    font-size: 1.5rem;
    position: relative;
    // top: 0.625rem;
    padding: 0 0.625rem;
  }
}

.social-icon2 {
  a {
    border: 0.1rem solid var(--primary);
    display: block;
    padding: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.3rem;
    font-size: 1.6rem;
  }

  i {
    font-size: 0.75rem;
    color: var(--primary);
  }
}

.social-icon3 {
  ul {
    li {
      display: inline-block;
      a {
        display: block;
        i {
          font-size: 1.4rem;
          padding: 1rem 0.7rem;
          color: $l-ctd;
        }
        &:hover {
          i {
            color: var(--primary);
          }
        }
      }
    }
  }
}

.social-icons-muted {
  ul li a i {
    color: $muted;
  }
}

.social-links {
  a {
    padding: 0.5rem;
  }
}
