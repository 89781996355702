.search-area {
  position: relative;
  overflow: hidden;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.search-area::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #007bff, #00c6ff);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.search-area.focused::before {
  transform: scaleX(1);
}

.search-area.has-value::before {
  transform: scaleX(0.5);
}

.search-area input {
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

.search-area.focused input {
  color: #333 !important;
}

.search-area i {
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  height: 100%;
}

.search-area:hover i {
  transform: scale(1.1);
}

.search-area.focused i {
  color: #007bff;
}

