// Variable.scss

.weather-one {
  i {
    font-size: 8rem;
    position: relative;
    top: 0.5rem;
  }

  h2 {
    display: inline-block;
    float: right;
    font-size: 4.8rem;
  }

  .city {
    position: relative;
    text-align: right;
    top: -2.5rem;
  }

  .currently {
    font-size: 1.6rem;
    font-weight: 400;
    position: relative;
    top: 2.5rem;
  }

  .celcious {
    text-align: right;
    font-size: 2rem;
  }
}
