.vertical-card__menu {
  // box-shadow: none;

  &:hover {
    box-shadow: none;
  }

  &--image {
    text-align: center;

    img {
      width: 100%;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
  }

  &--status {
    &.closed {
      background: $danger;

      &:after {
        border-top: 0.625rem solid $danger;
      }

      .ribbon-curve {
        border-top: 0.6875rem solid $danger;
        border-bottom: 0.625rem solid $danger;
      }
    }
  }

  &--desc {
    // padding: 0.9375rem 1.25rem;
    // border-left: 0.0625rem solid #e5e5e5;
    // border-right: 0.0625rem solid #e5e5e5;

    p {
      margin-bottom: 0.625rem;
    }
  }

  &--price {
    font-size: 1.25rem;
    margin: 0;
    font-weight: 700;
  }

  &--rating {
    font-size: 0.75rem;
  }

  &--title {
    // margin-bottom: 0.625rem;
    font-weight: 700;
  }

  &--button {
    button {
      font-size: 0.75rem;
    }
  }

  &--location {
    // font-size: 0.75rem;
  }

  .card-footer {
    padding: 0.9375rem 1.25rem;
    background-color: $border;
    // border: 0.0625rem solid #e5e5e5;
    border-top: none;

    @include custommq($min: 75rem, $max: 90rem) {
      padding: 0.9375rem;
    }

    span {
      color: $dark;
      margin-right: 0.3125rem;
    }
  }
}

.horizontal-card__menu {
  box-shadow: 0 0 0.4375rem rgba(173, 173, 173, 0.32);
  display: flex;
  flex-direction: row;

  @include media-breakpoint-down(xs) {
    display: block;
  }

  .card-body {
    padding: 0;
    padding: 1.25rem 1.875rem;
  }

  &--image {
    flex-basis: 30%;
    max-width: 30%;

    img {
      height: 100%;
      width: 100%;
    }

    @include media-breakpoint-down(xs) {
      max-width: unset;
      flex-basis: 100%;
    }
  }

  &--title {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.3125rem;
  }

  &--fav {
    margin-right: 0.5rem;
  }

  &--price {
    margin: 0;
    font-size: 1rem;
    font-weight: 700;
  }

  &--rating {
    font-size: 0.625rem;
  }

  &--footer {
    margin-top: 0.625rem;
  }
}

.prev_price {
  text-decoration: line-through;
  // color: $muted;
  font-size: 80%;
  opacity: 0.5;
}
