.doctor-info-details {
  @include respond("phone-land") {
    display: block !important;
  }
  .media {
    position: relative;
    img {
      @include respond("laptop") {
        width: 100%;
      }
    }
    @include respond("phone-land") {
      float: left;
    }
    @include respond("laptop") {
      width: 5rem;
      height: 5rem;
      margin-right: 1.25rem;
    }
    i {
      width: 4rem;
      height: 4rem;
      border-radius: 3.75rem;
      border: 0.1875rem solid #fff;
      line-height: 3.625rem;
      text-align: center;
      background: var(--primary);
      position: absolute;
      right: -0.9375rem;
      bottom: -0.9375rem;
      color: $white;
      font-size: 1.5rem;
      @include respond("laptop") {
        width: 3.125rem;
        height: 3.125rem;
        font-size: 1.125rem;
        line-height: 2.875rem;
      }
      @include respond("phone") {
        width: 2.1875rem;
        height: 2.1875rem;
        font-size: 1rem;
        line-height: 2.0625rem;
        right: -0.4375rem;
        bottom: -0.4375rem;
      }
    }
  }
  .media-body {
    padding-left: 2.5rem;

    @include respond("laptop") {
      padding-left: 1.25rem;
    }
    @include respond("phone-land") {
      padding-left: 0;
    }
    h2 {
      font-size: 2.5rem;
      line-height: 1.2;
      font-weight: 600;
      color: $black;
      @include respond("laptop") {
        font-size: 1.75rem;
      }
      @include respond("phone") {
        font-size: 1.25rem;
      }
    }
    p {
      font-size: 1.125rem;
      font-weight: 500;
      color: #3e4954;
    }
    span {
      color: #333333;

      i {
        transform: scale(1.3);
        display: inline-block;
        margin-right: 0.625rem;
      }
    }
  }
  .star-review i {
    font-size: 1.375rem;
    @include respond("laptop") {
      font-size: 1rem;
    }
  }
}
.doctor-info-content {
  p {
    line-height: 1.4;
  }
}
.review-box {
  border: 0.0625rem solid #f0f0f0;
  border-radius: 1.125rem;
  padding: 1.25rem 1.875rem 1.875rem 1.875rem;
  @include respond("laptop") {
    padding: 0.9375rem 0.9375rem 1.25rem 0.9375rem;
  }
  @include respond("phone-land") {
    display: block !important;
  }
  h4 {
    font-size: 1.25rem;
  }
  p {
    font-size: 0.875rem;
    line-height: 1.4;
  }
  img {
    @include respond("phone-land") {
      width: 3.75rem;
      float: left;
    }
  }
  .media-footer {
    min-width: 9.375rem;
    @include respond("laptop") {
      min-width: 6.875rem;
    }
  }
  .star-review {
    @include respond("phone-land") {
      margin-top: 0.9375rem;
    }
    span {
      display: block;
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 0.9375rem;
      line-height: 1.3;
      @include respond("phone-land") {
        font-size: 1rem;
        display: inline-block;
        margin-bottom: 0;
      }
    }
    i {
      font-size: 1.125rem;
      margin: 0 0.125rem;
      @include respond("laptop") {
        font-size: 1rem;
        margin: 0 0.0625rem;
      }
    }
  }
}
.patient-box {
  @include respond("phone-land") {
    display: block !important;
  }
  .up-sign {
    i {
      font-size: 3.125rem;
      line-height: 0.7;
    }
    @include respond("phone-land") {
      float: right;
    }
  }
  img {
    @include respond("phone-land") {
      width: 6.25rem;
      float: left;
    }
  }
}
.patient-calender {
  color: #fff;
  .bootstrap-datetimepicker-widget table {
    td,
    th {
      padding: 0.9375rem 0.3125rem;
      border-radius: $radius;
    }
    th {
      height: 1.25rem;
      line-height: 1.25rem;
      width: 1.25rem;
      font-weight: 400;
      opacity: 0.7;
      font-size: 0.875rem;
    }
    td.active,
    td.active:hover,
    span,
    i {
      color: $white;
    }
    thead tr:first-child th {
      font-size: 1.125rem;
      font-weight: 600;
      opacity: 1;
    }

    .btn-primary {
      border: 0;
      padding: 0.625rem;
    }
    .btn-primary,
    td span.active,
    td i.active,
    thead tr:first-child th:hover,
    td span:hover,
    td i:hover,
    td.day:hover,
    td.hour:hover,
    td.minute:hover,
    td.second:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }
  .datepicker table tr td.active,
  .datepicker table tr td.today {
    background: rgba(0, 0, 0, 0.2) !important;
  }
}
.abilities-chart {
  .ct-chart .ct-label {
    font-size: 1rem;
    fill: $black;
  }
  .ct-chart .ct-series.ct-series-a .ct-slice-donut {
    stroke: #209f84;
  }
  .ct-chart .ct-series.ct-series-b .ct-slice-donut {
    stroke: #07654e;
  }
  .ct-chart .ct-series.ct-series-c .ct-slice-donut {
    stroke: #93cbff;
  }
  .chart-point {
    font-size: 1rem;
    justify-content: space-between;
    margin-top: 2.5rem;
  }
  .chart-point .a,
  .chart-point .b,
  .chart-point .c {
    width: 2rem;
    height: 0.5rem;
    display: block;
    border-radius: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.625rem;
  }
  .chart-point .a {
    background: #07654e;
  }
  .chart-point .b {
    background: #209f84;
  }
  .chart-point .c {
    background: #93cbff;
  }
}
