.sidebar-footer {
  padding: 1.5rem 1rem;
  position: sticky;
  bottom: 0;
  width: 100%;
  background: linear-gradient(180deg, 
    rgba(255, 255, 255, 0.02) 0%, 
    rgba(255, 255, 255, 0.05) 100%
  );
  backdrop-filter: blur(10px);
  isolation: isolate;
  transition: opacity 0.3s ease;
  z-index: 10;
}

.sidebar-footer::before {
  content: '';
  position: absolute;
  inset: 0;
  background: radial-gradient(
    circle at top right,
    rgba(54, 153, 255, 0.08),
    transparent 60%
  );
  z-index: -1;
}

/* Logo Section */
.logo-section {
  margin-bottom: 1rem;
  perspective: 1000px;
}

.logo-wrapper {
  position: relative;
  padding: 0.8rem 1.2rem;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.03);
  transform-style: preserve-3d;
  transition: transform 0.3s ease;
  filter: drop-shadow(0 0 8px rgba(183, 132, 167, 0.2));
}

.logo-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  z-index: 2;
  transform: translateZ(0);
}

.logo-backdrop {
  position: absolute;
  inset: 0;
  border-radius: 12px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.05)
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  transform: translateZ(-10px);
  box-shadow: 
    0 4px 20px rgba(0, 0, 0, 0.1),
    0 2px 8px rgba(0, 0, 0, 0.15);
}

.logo-wrapper:hover {
  transform: translateY(-2px);
}

.logo-text {
  font-size: 1.3rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: transparent;
  background: linear-gradient(
    120deg,
    #89CFF0 0%, /* Baby Blue */
    #A7C7E7 15%, /* Pastel Blue */
    #B39EB5 30%, /* Pastel Purple */
    #C3B1E1 45%, /* Wisteria */
    #CCCCFF 60%, /* Periwinkle */
    #89CFF0 75% /* Back to Baby Blue */
  );
  background-size: 400% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 8s linear infinite;
}

.logo-text.accent {
  background: linear-gradient(
    120deg,
    #B784A7 0%, /* Rose Pink */
    #9B72AA 15%, /* French Mauve */
    #6F8FAF 30%, /* Shadow Blue */
    #7B68EE 45%, /* Medium Slate Blue */
    #B784A7 60% /* Back to Rose Pink */
  );
  background-size: 400% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 8s linear infinite;
  animation-delay: -4s; /* Offset animation for nice effect */
}

.logo-shine {
  display: none;
}

/* Divider */
.divider {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  gap: 8px;
}

.divider-line {
  flex: 1;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
}

.divider-icon {
  font-size: 0.4rem;
  color: rgba(255, 255, 255, 0.2);
  transform: translateY(-1px);
}

/* Copyright Section */
.copyright-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 0.75rem;
}

.copyright-text {
  font-size: inherit;
  color: #96a0af;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
}

.privacy-link {
  font-size: inherit;
  color: #b784a7;
  text-decoration: none;
  position: relative;
  white-space: nowrap;
  transition: all 0.3s ease;
  font-weight: 500;
}

/* .privacy-link::before {
  content: '•';
  margin: 0 6px;
  opacity: 0.5;
  color: #96a0af;
} */

.privacy-link:hover {
  color: #b784a7;
  text-shadow: 0 0 8px rgba(183, 132, 167, 0.5);
  transform: translateY(-1px);
  opacity: 0.8;
}

.privacy-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -2px;
  left: 0;
  background: linear-gradient(
    90deg,
    transparent,
    #b784a7,
    transparent
  );
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.privacy-link:hover::after {
  transform: scaleX(1);
}

.sidebar-footer:hover .copyright-section {
  opacity: 1;
}

/* Animations */
@keyframes shine {
  to {
    background-position: 200% center;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 400% 50%;
  }
}

/* Responsive hover effects */
@media (hover: hover) {
  .logo-wrapper {
    will-change: transform;
  }
  
  .logo-wrapper:hover .logo-backdrop {
    border-color: rgba(255, 255, 255, 0.2);
  }
  
  .logo-wrapper:active {
    transform: translateZ(10px) scale(0.98);
  }
} 

/* Add this new section for collapsed state */
.sidebar.collapsed .sidebar-footer {
  display: none;
}

.metismenu {
  padding-bottom: 200px !important; /* Add padding to prevent content from hiding behind footer */
}
  