@import "../../abstracts/variable";
@import "../../abstracts/mixin";
[data-sidebar-style="full"][data-layout="vertical"] {
  .dlabnav {
    .metismenu {
      & > li {
        /* padding:0 1.25rem; */
        & > a {
          font-size: 1rem;
          padding: 1.125rem 2.1875rem;
          @include transitionMedium;
          &:before {
            position: absolute;
            content: "";
            background: var(--primary);
            width: 0;
            height: 100%;
            top: 0;
            right: 0rem;
            @include transitionMedium;
            border-radius: 1.25rem;
          }
        }
        &.mm-active {
          & > a {
            &:before {
              width: 0.563rem;
              @include respond("laptop") {
                width: 0.6rem;
              }
            }

            i {
              color: var(--primary);
              font-weight: 900;
            }
          }
        }
        .has-arrow:after {
          right: 1.5rem;
        }
        @include respond("laptop") {
          padding: 0 0;
          & > a {
            font-size: 1rem;
            padding: 0.9375rem 1.25rem;
          }
        }
      }
    }
    .header-profile2 {
      .sidebar-info {
        width: 100%;
        i {
          margin-left: auto;
          color: #393939;
        }
      }
    }
  }
  .menu-toggle {
    .nav-header {
      width: 6.5rem;
      z-index: 999;
      .brand-logo {
        padding-left: 0;
        padding-right: 0;
        justify-content: center;
      }
      .nav-control {
        right: -4rem;
        .hamburger {
          .line {
            background-color: var(--primary);
          }
        }
      }
    }
    .copyright,
    .plus-box {
      display: none;
    }
    .header {
      padding-left: 7.5rem;
      width: 100%;
      @at-root [direction="rtl"]#{&} {
        padding: 0 0.9375rem;
        padding-right: 7.5rem;
      }
      .header-content {
        padding-left: 5rem;
      }
    }
    .dlabnav {
      width: 6.5rem;
      overflow: visible;
      position: absolute;
      .nav-text {
        display: none;
      }
      .slimScrollDiv,
      .dlabnav-scroll {
        overflow: visible !important;
      }
      .header-info2 {
        padding: 0;
      }
      .sidebar-info {
        display: none !important;
      }
      .header-profile2 {
        margin: 0 0.5rem;
      }
      .metismenu {
        li {
          position: relative;

          a {
            background: transparent;
            margin: 0.125rem 0;

            svg {
              max-width: 1.5rem;
              max-height: 1.5rem;
              margin-right: 0;
            }
            &:before {
              content: none;
            }
            i {
              margin: 0;
            }
            .badge-xs {
              &.style-1 {
                margin-left: -5px;
                margin-top: 5px;
              }
            }
          }
          & > ul {
            position: absolute;
            left: 5.8rem;
            top: 0;
            width: 12rem;
            z-index: 1001;
            display: none;
            padding-left: 0.0625rem;
            height: auto !important;
            box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
            border-radius: 0.375rem;
            margin-left: 0;
            border: 0;
            background: $white;

            @at-root [direction="rtl"]#{&} {
              left: auto;
              right: 5rem;
              // box-shadow: -0.375rem 0.375rem 0.625rem rgba(0, 0, 0, 0.15);
            }
            li:hover {
              ul {
                // display: block;
                left: 11.8125rem;
                top: 0;
                &:after {
                  content: none;
                }
              }
            }
          }
          &:hover > ul {
            display: block;
            height: auto;
            overflow: visible;
          }
        }
        & > li {
          transition: all 0.4s ease-in-out;
          padding: 0 1.125rem;

          & > a {
            padding: 1.25rem 1.125rem;
            text-align: center;
            border-radius: $radius;

            &.has-arrow {
              &:after {
                display: none;
              }
            }
          }
          &.mm-active > a {
            background: var(--rgba-primary-1);
            border-radius: $radius;

            i {
              color: var(--primary);
              padding: 0;
            }
          }
          &:hover {
            &:nth-last-child(-n + 1) {
              & > ul {
                bottom: 0;
                top: auto;
              }
            }
            & > a {
              border-radius: $radius;
              background: var(--rgba-primary-1);
              color: var(--primary);
              @at-root [data-theme-version="dark"]#{&} {
                background: $dark-card;
              }
              i {
                color: var(--primary);
                /* background:$primary; */
              }
            }
            & > ul {
              height: auto !important;
              padding: 0.625rem 0;

              a {
                padding: 0.375rem 1.25rem 0.375rem 1.25rem;
                margin-left: -0.1rem;
              }
              ul {
                padding: 0.625rem 0;
                a {
                  padding: 0.375rem 1.25rem 0.375rem 1.25rem;
                  margin-left: -0.1rem;
                }
              }
            }
          }
        }
        .nav-label,
        .nav-badge {
          display: none;
        }
      }
    }
    .content-body {
      margin-left: 6.5rem;
      @at-root [direction="rtl"]#{&} {
        margin-right: 5.7rem;
        margin-left: auto;
        border: 0;
      }
    }
    & + .footer {
      padding-left: 5.7rem;
      @at-root [direction="rtl"]#{&} {
        padding-left: 0;
        padding-right: 5.7rem;
      }
    }
  }
}
[data-sidebar-style="full"][data-layout="horizontal"] {
  .header .header-content {
    padding-left: 1.875rem;
  }
}

[data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="full"][data-container="wide-boxed"] {
  .content-body {
    padding-top: 13.5rem;
  }
}
