[data-theme-version="transparent"] {
  .dlabnav {
    background-color: rgba(0, 0, 0, 0.15) !important;

    .metismenu {
      & > li {
        & > a {
          color: rgba(0, 0, 0, 0.15);
        }

        &:hover,
        &:focus,
        &.mm-active {
          & > a {
            background-color: rgba(0, 0, 0, 0.15) !important;
            color: $white;

            &::after {
              border-color: transparent transparent $white transparent;
            }
          }
        }

        &.mm-active {
          ul {
            ul {
              background-color: transparent;
            }
          }
        }
      }

      //one step dropdown
      ul {
        background-color: rgba(0, 0, 0, 0.15);

        a {
          &:hover,
          &:focus,
          &.mm-active {
            color: $white;
          }
        }
      }

      a {
        color: rgba(0, 0, 0, 0.15);
      }
      ul {
        background-color: rgba(0, 0, 0, 0.15) !important;
      }

      .has-arrow {
        &:after {
          border-color: transparent transparent rgba(0, 0, 0, 0.15) transparent;
        }
      }
    }
  }
}
