#daily-sales,
#comparison-rate,
#usage-chart,
#walet-status {
  width: 100%;
  display: block;

  canvas {
    max-width: 100% !important;
    width: 100% !important;
  }
}

#sparkline-composite-chart,
#composite-bar,
#sparkline11,
#StackedBarChart,
#spark-bar,
#tristate {
  canvas {
    height: 6.25rem !important;
  }
}

#sparkline11 canvas {
  width: 6.25rem !important;
}
