.profile-form {
  .card-header {
    border-color: $border-color;
    padding: 30px;
    .title {
      position: relative;
      margin-bottom: 0;
      color: $dark;
      &:after {
        content: "";
        height: 3px;
        width: 100%;
        background-color: var(--primary);
        position: absolute;
        bottom: -31px;
        left: 0;
      }
    }
  }
  .m-b30 {
    margin-bottom: 30px;
  }
  .card-body {
    padding: 40px 40px 10px;
  }
  .card-footer {
    padding: 35px 40px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-color: $border-color;
    .btn-lg {
      padding: 18px 50px;
      font-size: 15px;
    }
    .btn-link {
      font-size: 15px;
    }
  }
  @include respond("phone") {
    .card-header {
      padding: 25px;
      .title {
        &:after {
          bottom: -26px;
        }
      }
    }
    .card-body {
      padding: 25px 25px 0;
    }
    .card-footer {
      padding: 25px 25px 25px;
      .btn-lg {
        padding: 18px 30px;
      }
    }
  }
}

.author-profile {
  text-align: center;
  .card-body {
    padding: 0;
  }
  .author-media {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    display: inline-block;
    img {
      width: 130px;
      border-radius: 100%;
    }
  }
  .author-info {
    .title {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 0;
    }
    span {
      display: block;
      color: $body-color;
      @at-root [data-theme-version="dark"] & {
        color: $white;
      }
    }
  }
  .info-list {
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
      border-top: 1px solid $border-color;
      padding: 18px 30px;
      color: $dark;
      a {
        color: $dark;
        @at-root [data-theme-version="dark"] & {
          color: $white;
        }
      }
      span {
        color: $body-color;
        font-weight: 500;
        @at-root [data-theme-version="dark"] & {
          color: $white;
        }
      }
      @at-root [data-theme-version="dark"] & {
        border-top: 1px solid $d-border;
      }
    }
  }
  .card-footer {
    padding: 30px;
    display: block;
    .form-control {
      height: 45px;
      border-color: $border-color;
      overflow: hidden;
      line-height: 30px;
      @at-root [data-theme-version="dark"] & {
        background-color: $dark-card;
        border-color: $d-border;
      }
    }
  }
  .upload-link {
    position: absolute;
    width: 35px;
    height: 35px;
    line-height: 32px;
    background: #395ee2;
    bottom: 0;
    right: 0px;
    box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
    border-radius: 100%;
    color: #fff;
    overflow: hidden;
    border: 2px solid #fff;
    .update-flie {
      position: absolute;
      opacity: 0;
      z-index: 0;
      width: 100%;
      cursor: pointer;
      left: 0;
      height: 100%;
    }
  }
}
