.cm-content-box {
  background: $white;
  border-radius: 0.6rem;
  //overflow: hidden;
  margin-bottom: 1rem;
  &.filter {
    border-top: 0;
  }
  .content-title {
    padding: 0.5rem 1.875rem;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cpa {
      color: var(--primary);
      padding: 11px 0 9px 0;
      font-weight: 600;
      font-size: 1rem;
    }
    @include respond("phone") {
      padding: 0.5rem 0.938rem;
    }
  }

  .content-title {
    //background-color: var(--rgba-primary-8);
    border-top-left-radius: 0.425rem;
    border-top-right-radius: 0.425rem;
    border-bottom: 1px solid #eee;
  }
  &.style-1 {
    .content-title {
      border-radius: $radius;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: 1px solid $border;
    }
  }

  .tools {
    a {
      display: inline-block;
      &:hover {
        i {
          opacity: 0.5;
        }
      }
      i {
        font-family: "Font Awesome 6 Free";
        font-weight: 600;
        color: #c2c2c2;
        font-size: 1.25rem;
      }
      &.expand {
        transform: rotate(-180deg);
      }
    }
  }
  .cm-content-body {
    //background-color: $white;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
    .table {
      thead {
        tr {
          th {
            .custm-select {
              height: 45px;
              background-color: transparent;
              border-radius: $radius;
              &.input-small {
                width: 9.063rem !important;
              }
            }
            .form-control {
              border-radius: $radius;
              &.input-large {
                width: 20rem !important;
              }
            }
          }

          &:nth-child(2) {
            background-color: #000;
            box-shadow: 10px 10px 8px #888888;
          }
        }
      }
      &.table-striped {
        th {
          padding: 1rem;
          color: $body-color;
        }
        td {
          &:first-child {
            padding-left: 2rem;
          }
          padding: 1rem;
          color: #504f4f;
          white-space: nowrap;
        }
      }
      &.table-bordered {
        thead {
          background-color: unset;
        }
      }
    }
    .table .btn {
      margin-top: 0px;
      margin-left: 0px;
      margin-right: 0.313rem;
    }
    &.excerpt {
      //add page
      .textarea {
        padding: 0.438rem 0.25rem;
      }
    }
    &.custom-fields {
      .name-value {
        line-height: 2.375rem;
        font-size: 1rem;
        text-align: center;
      }
    }
    .contact {
      border-radius: $radius !important;
    }
    .collapse {
      &.show {
        margin-bottom: 0.625rem;
      }
    }
  }
}

.contents-list {
  .btn-icon-only {
    height: 2.25rem;
    width: 2.25rem;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    line-height: 1;
    i {
      font-size: 1rem;
    }
  }
  .table {
    tbody {
      tr {
        td {
          padding: 0.4375rem 0.625rem;
        }
      }
    }
  }
}
.fatch-date {
  display: block;
}
.new-scroll {
  background-color: $light;
  padding: 0.625rem;
  height: 9.375rem;
  overflow-y: auto;
  border-radius: $radius;
}

.content-icon {
  width: 2.188rem;
  height: 2.188rem;
  display: inline-block;
  text-align: center;
  line-height: 2.125rem;
  font-size: 0.625rem;
  padding: 0px;
}

.dd-handle {
  &.move-media {
    padding: 0.5rem 1.125rem;
    display: flex;
    margin: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 100%;
    border: 0;
    z-index: 2;
    background: none;
    position: absolute;
    background-color: var(--rgba-primary-8);
    top: 0;
    font-size: 1.25rem;
    left: 0;
    align-items: center;
    & + .accordion-button {
      padding-left: 4.063rem;
      background: #fff;
      position: relative;
      z-index: 1;
    }
  }
}

.menu-ac-item {
  position: relative;
  .dd-collapse {
    height: 3.338rem;
    width: 3.338rem;
    border: 1px solid #d3d3d3;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    font-size: 1.75rem;
    color: black;
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
    & + .accordion {
      margin-left: 3.25rem;
    }
    @include custommq($max: 1600px) {
      height: 3rem;
      width: 3rem;
    }
  }
  .dd-expand {
    display: none;
  }
}

.dz-menu-btn {
  margin-top: 0.625rem;
  @include custommq($max: 1600px) {
    margin-top: 0;
    margin-left: 0.313rem;
  }
  @include custommq($max: 1480px) {
    margin-top: 0.625rem;
    margin-left: 0;
  }
}

.dz-terms {
  border: 1px solid $border-color;
  border-radius: $radius;
  padding: 0.625rem;
}

/* menu-scss */

.required {
  color: $danger;
}
.tab-my {
  &.nav-tabs {
    border-bottom: unset;

    .nav-link {
      background-color: $light;
      border-radius: $radius;
      border: 1px solid transparent;
      margin: 8px 2px;
      color: $body-color;

      &.active {
        background-color: var(--primary);
        color: $white;
      }
    }
  }
}
.menu-tabs {
  border: 1px solid $light;
  margin-bottom: 1rem;
  padding: 0.625rem;
  border-radius: $radius;
}

.avatar-upload {
  .avatar-edit {
    position: absolute;
    right: 0.75rem;
    z-index: 1;
    top: 0.625rem;
  }
  .avatar-preview {
    border: 1px solid $border-color;
    padding: 0.5rem;
    margin-bottom: 0.625rem;
    border-radius: $radius;
    > div {
      display: block;
      width: 100%;
      height: 7.5rem;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      //border-radius:$radius-sm;
    }
  }
}
.main-check {
  border: 1px solid var(--primary);
  padding: 2.125rem;
  margin-bottom: 1rem;
  border-radius: $radius;
}
/* view-content */
.view-bottom {
  flex-direction: column;
  justify-content: end;
  height: 88%;
  display: flex;
}
/* add-email */

.publish-content {
  ul {
    padding: 0.625rem;
    &:hover {
      background-color: var(--rgba-primary-1);
      border-radius: $radius;
    }
  }
}

.dd {
  .accordion-button:not(.collapsed) {
    box-shadow: unset;
  }
  .accordion-body {
    border: 1px solid $border-color;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-top: 0;
    margin-top: -5px;
  }
  .dd-list {
    @include respond("phone") {
      padding-left: 0;
    }
  }
}
// .collapse{
// 	border-radius:$radius;
// }
.publish-drop {
  ul {
    &:hover {
      background-color: unset;
    }
  }
}
input.form-control {
  position: relative;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.right-sidebar-sticky {
  position: sticky;
  top: 7.5rem;
}
.content-title {
  &.box-primary,
  &.box-secondary,
  &.box-success,
  &.box-danger,
  &.box-warning,
  &.box-info,
  &.box-dark,
  &.box-light {
    background-color: var(--primary);
    .cpa,
    i {
      color: $white !important;
    }
  }
  &.box-secondary {
    background-color: var(--secondary);
  }
  &.box-success {
    background-color: $success;
  }
  &.box-danger {
    background-color: $danger;
  }
  &.box-warning {
    background-color: $warning;
  }
  &.box-info {
    background-color: $info;
  }
  &.box-dark {
    background-color: $dark;
  }
  &.box-light {
    background-color: $light;
  }
}

.accord-data {
  .nestable-list {
    &.nestable-group {
      padding-left: 0;
    }
  }
  .menu-accord {
    .accordion-body {
      background-color: var(--rgba-primary-1);
    }
    .accordion-button {
      padding-left: 4rem;
    }
  }
}
