.dlab-demo-panel {
  right: -23.75rem;
  position: fixed;
  top: 0;
  width: 23.75rem;
  background-color: $white;
  height: 100vh;
  transition: all 0.5s ease-in-out;
  z-index: 9999;
  .note-text {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    font-size: 12px;
    background-color: #fff;
    color: #9b9a9a;
  }
  .dlab-demo-trigger {
    position: absolute;
    z-index: 9;
    top: 21.75rem;
    right: 100%;
    background-color: #627eea;
    color: #fff;
    display: inline-block;
    height: 3rem;
    width: 3rem;
    text-align: center;
    font-size: 1.75rem;
    line-height: 3rem;
    border-radius: 0.3125rem 0 0 0.3125rem;
    box-shadow: -0.3125rem 0.1875rem 0.3125rem 0rem rgba(119, 119, 119, 0.15);

    @include custommq($max: 63.9375rem) {
      display: none;
    }
  }
  .dlab-demo-close {
    height: 1.875rem;
    color: $white;
    width: 1.875rem;
    border-radius: $radius;
    background: rgba($black, 0.5);
    line-height: 1.875rem;
    text-align: center;
  }
  &.show {
    right: 0;
    box-shadow: 0rem 0rem 3.125rem rgba(0, 0, 0, 0.2);
    z-index: 99999;
    overflow: hidden;
    .bg-close {
      position: fixed;
      z-index: -2;
      cursor: pointer;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba($black, 0.15);
    }
  }
  .dlab-demo-inner {
    padding: 1.875rem 1.875rem;
    background: $white;
  }
  .dlab-demo-content {
    height: calc(100vh - 200px);
  }
  .dlab-demo-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;
    h4 {
      margin-bottom: 0;
      color: $black;
    }
  }
  .dlab-demo-bx {
    height: 12.5rem;
    overflow: hidden;
    border: 0.3125rem solid #efefef;
    box-shadow: 0rem 0rem 0.3125rem rgba(0, 0, 0, 0.1);
    margin-bottom: 0.625rem;
    &.demo-active {
      border-color: $secondary;
      .overlay-layer {
        opacity: 1;
      }
    }
  }
  .overlay-bx {
    position: relative;
    .overlay-layer {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.1);
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      opacity: 0;
    }
    &:hover {
      .overlay-layer {
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        opacity: 1;
      }
    }
  }
}
