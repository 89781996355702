body {
    background-color: #f9f9f9;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

header {
    background: linear-gradient(135deg, #d0e8f2, #e4d8f9);
}

.card {
    border: none;
    border-radius: 16px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background: #fdfdff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
}

.progress {
    height: 8px;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.5);
}

.progress-bar-striped {
    background-size: 1rem 1rem;
}

.org-card {
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 16px;
}

.org-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.15);
}

.org-card img {
    opacity: 0.15;
    transition: opacity 0.3s ease;
}

.org-card:hover img {
    opacity: 0.3;
}

.plan-circle {
    position: relative;
    z-index: 2;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.3s ease;
}

.plan-circle i {
    font-size: 1.25rem;
}

.bg-blue {
    background: #0d6efd;
}

.bg-green {
    background: #198754;
}

.bg-warning {
    background: #ffc107;
}

.bg-purple {
    background: #6f42c1;
}

.text-purple {
    color: #6f42c1;
}

h2, h3, p, .fw-bold {
    color: #333;
}

h2.text-muted {
    color: #666 !important;
}

.analysis-stats .stat-item {
    padding: 1.25rem;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 12px;
    transition: all 0.3s ease;
}

.analysis-stats .stat-item:hover {
    background: rgba(255, 255, 255, 0.8);
    transform: translateY(-2px);
}

.badge {
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-badge-text {
  font-size: 1rem !important;
}

.display-6 {
    font-size: 1.8rem;
}

hr {
    border-color: currentColor;
    margin: 1.25rem 0;
}

.text-muted {
    font-size: 0.875rem;
}

.fw-bold {
    letter-spacing: 0.2px;
}

/* Enhanced Overview Cards Styling */
.overview-card {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition: all 0.3s ease;
}

.overview-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
}

/* Icon Styling */
.icon-wrapper {
    position: relative;
    display: inline-block;
}

.icon-glow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 45px;
    height: 45px;
    border-radius: 50%;
    z-index: 1;
    filter: blur(10px);
    opacity: 0.5;
    transition: all 0.3s ease;
}

.overview-card:hover .icon-glow {
    width: 55px;
    height: 55px;
    opacity: 0.7;
}

/* Glow Colors */
.blue-glow { background: #0d6efd; }
.green-glow { background: #198754; }
.warning-glow { background: #ffc107; }
.purple-glow { background: #6f42c1; }

/* Gradient Text */
.text-gradient-primary {
    background: linear-gradient(45deg, #0d6efd, #0099ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-gradient-success {
    background: linear-gradient(45deg, #198754, #00cc66);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-gradient-warning {
    background: linear-gradient(45deg, #ffc107, #ffaa00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-gradient-purple {
    background: linear-gradient(45deg, #6f42c1, #8a63d2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Soft Background Badges */
.bg-soft-primary {
    background: rgba(13, 110, 253, 0.1);
    color: #0d6efd !important;
}

.bg-soft-success {
    background: rgba(25, 135, 84, 0.1);
    color: #198754 !important;
}

.bg-soft-warning {
    background: rgba(255, 193, 7, 0.1);
    color: #976805 !important;
}

.bg-soft-danger {
    background: rgba(220, 53, 69, 0.1);
    color: #dc3545 !important;
}

.bg-soft-purple {
    background: rgba(111, 66, 193, 0.1);
    color: #6f42c1 !important;
}

/* Stats Styling */
.stat-label {
    font-size: 0.875rem;
    color: #344054 !important;
    font-weight: 500;
}

.stat-value {
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0.5rem 0.75rem;
}

.growth-badge {
    padding: 0.5rem 0.75rem;
    font-weight: 600;
}

/* Progress Bars */
.progress {
    height: 6px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.05);
    overflow: hidden;
}

.progress-bar {
    transition: width 1s ease;
}

/* Card Content Spacing */
.stat-content {
    padding-left: 1rem;
}

.growth-stats, .job-stats, .hire-time, .success-rate {
    padding-top: 0.5rem;
}

/* Add Font Awesome Icons */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css'); 

/* Card Title */
.card-title {
    font-size: 0.875rem;
    letter-spacing: 0.5px;
}

/* Stat Value Styling */
.stat-value-wrapper {
    text-align: left;
    position: relative;
    padding: 0.5rem 0;
}

.stat-value-lg {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.2;
    background: linear-gradient(45deg, #2196F3, #0D47A1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Label Styling */
.stat-label {
    font-size: 0.875rem;
    color: #6c757d;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

/* Badge Styling */
.stat-badge, .growth-badge {
    font-size: 0.875rem;
    padding: 0.5rem 0.75rem;
    font-weight: 600;
    letter-spacing: 0.3px;
    border-radius: 6px;
    background: transparent;
}

/* Progress Bar */
.progress {
    height: 6px;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.05);
}

.progress-bar {
    transition: width 1s ease;
}

/* Card Spacing */
.stat-content {
    padding-top: 0.5rem;
}

.growth-stats, .job-stats, .hire-time, .success-rate {
    padding-top: 0.5rem;
}

.growth-item {
    margin-bottom: 1rem;
}

.growth-item:last-child {
    margin-bottom: 0;
}

/* Soft Background Colors */
.bg-soft-primary {
    background: rgba(13, 110, 253, 0.1);
    color: #0d6efd !important;
}

.bg-soft-success {
    background: rgba(25, 135, 84, 0.1);
    color: #198754 !important;
}

.bg-soft-warning {
    background: rgba(255, 193, 7, 0.1);
    color: #976805 !important;
}

.bg-soft-danger {
    background: rgba(220, 53, 69, 0.1);
    color: #dc3545 !important;
}

.bg-soft-purple {
    background: rgba(111, 66, 193, 0.1);
    color: #6f42c1 !important;
}

/* Gradient Text */
.text-gradient-primary {
    background: linear-gradient(45deg, #0d6efd, #0099ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.text-gradient-success {
    background: linear-gradient(45deg, #4CAF50, #1B5E20);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.text-gradient-warning {
    background: linear-gradient(45deg, #FFC107, #FF6F00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.text-gradient-purple {
    background: linear-gradient(45deg, #9C27B0, #4A148C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

/* Icon and Circle Styling */
.plan-circle {
    position: relative;
    z-index: 2;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.3s ease;
}

.plan-circle i {
    font-size: 1.25rem;
}

/* Background Colors */
.bg-blue {
    background: #0d6efd;
}

.bg-green {
    background: #198754;
}

.bg-warning {
    background: #ffc107;
}

.bg-purple {
    background: #6f42c1;
}

/* Optional: Add hover effect for better interactivity */
.icon-wrapper:hover .plan-circle {
    transform: scale(1.1);
}

.icon-wrapper:hover .icon-glow {
    transform: translate(-50%, -50%) scale(1.2);
}

/* Add this for wider containers */
.container {
    max-width: 95% !important;
    margin-left: auto;
    margin-right: auto;
}

/* For very large screens, we can set a max-width */
@media (min-width: 1600px) {
    .container {
        max-width: 1800px !important;
    }
}

/* Add styles for the stat items */
.growth-item, .job-stats > div, .hire-time > div, .success-rate > div {
    padding: 0.5rem 0;
}

/* Update text colors for better contrast */
.text-muted {
    color: #495057 !important;
}

/* Add hover effect for better interaction */
.stat-badge:hover, .growth-badge:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
}

/* Ensure all values are prominent */
.growth-item, .job-stats, .hire-time, .success-rate {
    color: #344054;
}

/* Make all numbers and values bold */
.stat-value, .growth-value {
    font-weight: 600;
    color: #1f2937;
}

/* Financial Section Styles */
.icon-glow {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  filter: blur(10px);
  opacity: 0.3;
  z-index: 0;
}

.success-glow {
  background: #28a745;
}

.primary-glow {
  background: #0d6efd;
}

.info-glow {
  background: #17a2b8;
}

.stat-card {
  transition: transform 0.2s;
}

.stat-card:hover {
  transform: translateY(-2px);
}

.transaction-card {
  transition: transform 0.2s;
}

.transaction-card:hover {
  transform: translateY(-2px);
}

.profit-metrics .progress {
  border-radius: 10px;
}

.net-profit {
  border-left: 4px solid #0d6efd;
}

.text-gradient-success {
  background: linear-gradient(45deg, #28a745, #20c997);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Modern Financial Section Styles */
.financial-card {
  border: none;
  transition: all 0.3s ease;
  border-radius: 1rem;
  background: #ffffff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.financial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.metric-card {
  background: linear-gradient(145deg, #ffffff, #f8f9fa);
}

.bg-gradient-primary {
  background: linear-gradient(45deg, #4e73df, #224abe);
}

.metric-icon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.transaction-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-soft-primary {
  background-color: rgba(78, 115, 223, 0.1);
  color: #4e73df;
  border: none;
}

.btn-soft-primary:hover {
  background-color: rgba(78, 115, 223, 0.2);
  color: #4e73df;
}

.transaction-details {
  background: rgba(0, 0, 0, 0.02);
  border-radius: 1rem;
  transition: all 0.3s ease;
}

.transaction-details:hover {
  background: rgba(0, 0, 0, 0.04);
}

.progress {
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.05);
}

.progress-bar {
  transition: width 1s ease;
}

.metric-trend .badge {
  padding: 0.5rem 0.75rem;
  font-weight: 500;
}

/* Responsive Adjustments */
@media (max-width: 992px) {
  .financial-metrics .row {
    flex-direction: column;
  }
  
  .metric-card {
    margin-bottom: 1rem;
  }
}

/* Add these new classes for the highlight boxes */
.stat-highlight-box {
  transition: all 0.3s ease;
}

.stat-highlight-box:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

/* Different background gradients for different card types */
.stat-highlight-box.primary {
  background: linear-gradient(135deg, rgba(33, 150, 243, 0.1), rgba(13, 71, 161, 0.1));
  border: 1px solid rgba(33, 150, 243, 0.1);
}

.stat-highlight-box.success {
  background: linear-gradient(135deg, rgba(76, 175, 80, 0.1), rgba(27, 94, 32, 0.1));
  border: 1px solid rgba(76, 175, 80, 0.1);
}

.stat-highlight-box.warning {
  background: linear-gradient(135deg, rgba(255, 193, 7, 0.1), rgba(255, 111, 0, 0.1));
  border: 1px solid rgba(255, 193, 7, 0.1);
}

.stat-highlight-box.purple {
  background: linear-gradient(135deg, rgba(156, 39, 176, 0.1), rgba(74, 20, 140, 0.1));
  border: 1px solid rgba(156, 39, 176, 0.1);
}

/* Card Base Styles */
.overview-card, .financial-card {
  background: #ffffff;
  border: none;
  border-radius: 16px;
  transition: all 0.3s ease;
}

.overview-card:hover, .financial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

/* Stat Highlight Box Improvements */
.stat-highlight-box {
  flex-grow: 0;
  margin: 1rem 0;
  padding: 1.25rem;
  border-radius: 12px;
}

/* Gradient Text Variations */
.text-gradient-primary {
  background: linear-gradient(45deg, #2196F3, #0D47A1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.text-gradient-success {
  background: linear-gradient(45deg, #4CAF50, #1B5E20);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.text-gradient-warning {
  background: linear-gradient(45deg, #FFC107, #FF6F00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.text-gradient-purple {
  background: linear-gradient(45deg, #9C27B0, #4A148C);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

/* Additional Stats Styling */
.growth-stats, .job-stats, .hire-time, .success-rate {
  padding-top: 1rem;
}

.stat-item {
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  margin-bottom: 0.75rem;
  transition: all 0.3s ease;
}

.stat-item:hover {
  background: rgba(255, 255, 255, 0.8);
  transform: translateY(-2px);
}

/* Responsive Adjustments */
@media (max-width: 992px) {
  .stat-highlight-box {
    padding: 1rem;
  }
  
  .stat-value-lg {
    font-size: 2rem;
  }
}

.overview-card {
  display: flex;
  flex-direction: column;
}

.stat-highlight-box {
  flex-grow: 0;
  margin: 1rem 0;
  padding: 1.25rem;
  border-radius: 12px;
}

.stats-footer {
  margin-top: auto;
  padding-top: 0.5rem;
}

.stat-badge {
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.growth-item, 
.stats-footer > div {
  padding: 0.5rem 0;
}