.loader-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 999;
}
.loader {
  width: 4rem; /* w-16 */
  height: 4rem; /* h-16 */
  border-width: 4px; /* border-4 */
  border-color: #3b82f6 transparent transparent transparent; /* border-blue-500 and border-t-transparent */
  border-style: solid; /* border-solid */
  border-radius: 50%; /* rounded-full */
  animation: spin 1s linear infinite; /* animate-spin */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

input.form-control::placeholder {
  color: lightgray;
}

/* div select.form-select option:first-child {
  color: lightgray; 
} */

.default-option {
  color: lightgray;
}
