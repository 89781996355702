//header styles
[direction="rtl"] {
  .header {
    padding: 0;
    padding-right: 21.563rem;

    @include media-breakpoint-down(sm) {
      padding-right: 5rem;
      padding-left: 0;
    }

    .header-content {
      padding-left: 1.875rem;
      padding-right: 5.3125rem;

      @include custommq($max: 35.9375rem) {
        padding-right: 3.5rem;
        padding-left: 0.938rem;
      }
      @at-root [data-sidebar-style="compact"]#{&} {
        padding-right: 1.875rem;
      }
      @at-root [data-sidebar-style="modern"]#{&} {
        padding-right: 5.3125rem;
      }
      @at-root [data-sidebar-style="overlay"]#{&} {
        padding-right: 5.3125rem;
        @include respond("phone") {
          padding-right: 0.5rem;
        }
      }
    }

    .nav-control {
      right: 0.4375rem;
      left: auto;
    }
  }
  .header-right > li:not(:first-child) {
    padding-left: 0;
    padding-right: 1.25rem;
    margin-right: 0 !important;
    @include respond("phone-land") {
      padding-right: 0.5rem;
    }
  }
  .header-right {
    //search-area
    .search-area {
      .input-group-append {
        .input-group-text {
          padding-right: auto;
          padding-left: 1.25rem;
        }
      }
      .form-control {
        padding-left: auto;
        padding-right: 1.25rem;
      }
    }
    .header-profile {
      & > a.nav-link {
        margin-left: auto;
        padding-left: auto;
        margin-right: 0.9375rem;
        padding-right: 1.875rem;
        border-right: 0.0625rem solid $border-color;
        border-left: 0;
        .header-info {
          padding-right: 1.25rem;
          padding-left: auto;
          text-align: right;
          @include respond("laptop") {
            padding-right: 0.625rem;
          }
        }
        @include respond("laptop") {
          margin-right: 0.625rem;
          padding-right: 1.25rem;
        }
        @include respond("phone") {
          margin-right: 0rem;
          padding-right: 0rem;
          border-right: 0;
        }
      }
    }
  }
  .header-left {
    .search_bar {
      .dropdown-menu,
      .dropdown-menu.show {
        right: 2.5rem !important;
        @include respond("phone") {
          right: -6.25rem !important;
        }
      }
      .search_icon {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5rem;
        border-bottom-right-radius: 5rem;
        @include respond("phone") {
          border-radius: 5rem;
        }
      }
    }
  }

  &[data-layout="horizontal"] {
    .header {
      padding: 0;
      padding-right: 21.563rem;

      .header-content {
        padding-right: 2.5rem;
        padding-left: 2.5rem;
      }
    }
    &[data-sidebar-style="full"] {
      .nav-header .brand-logo {
        padding-right: 2.5rem;
      }
    }
  }
  &[data-layout="horizontal"][data-sidebar-style="mini"] {
    .header {
      padding-right: 7.75rem;
    }
  }
  &[data-sidebar-style="mini"] {
    .header {
      padding-right: 6.25rem;
    }
  }

  &[data-sidebar-style="compact"] {
    .header {
      padding: 0 0.9375rem;
      padding-right: 11.25rem;
    }
    &[data-layout="horizontal"] {
      .header {
        padding: 0;
        padding-right: 21.563rem;
      }
    }
  }
  &[data-sidebar-style="modern"] {
    .header {
      padding: 0 0.9375rem;
      padding-right: 10.625rem;
    }
    &[data-layout="horizontal"] {
      .header {
        padding: 0;
        padding-right: 16rem;
      }
    }
  }
}
